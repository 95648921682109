<script lang="ts">
  import { getFormattedDate, getFormattedNumber } from '../utils/formatters';
  import { t } from 'svelte-i18n';
  import { mCreditStore } from '../../store/mCreditStore';
  import MCreditDueAmountSection from './MCreditDueAmountSection.svelte';
</script>

<section
  class="flex h-36 flex-col justify-between"
  data-cy="mcredit-due-amount"
>
  <MCreditDueAmountSection
    titleLeft={$t('mcredit.due_amount')}
    valueLeft={getFormattedNumber($mCreditStore.mCreditSummary.dueAmount, 0)}
    titleRight={$t('mcredit.overdue_amount')}
    valueRight={getFormattedNumber(
      $mCreditStore.mCreditSummary.overdueAmount,
      0
    )}
    currencyCode={$mCreditStore.mCreditSummary.currencyCode}
    isTextRed={$mCreditStore.mCreditSummary.overdueAmount > 0}
    showCurrency
  />

  <hr class="text-primary-tint20" />

  <MCreditDueAmountSection
    titleLeft={$t('mcredit.debit_period')}
    valueLeft={$mCreditStore.creditSettle.creditTpContract}
    titleRight={$t('mcredit.next_due_date')}
    valueRight={getFormattedDate($mCreditStore.mCreditSummary.nextDueDate)}
  />
</section>
