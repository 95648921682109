<script lang="ts">
  import { settingsStore } from '../../store/settingsStore';
  import { isAndroid } from '../utils/detectPlatform';
  import featureFlagsStatus from '../utils/featureFlags';

  const baseClasses = ['bg-primary-darken20 px-6 transition-all duration-300'];

  const getFirstTabClasses = () => {
    if (!$settingsStore.accountDetails.mcreditAccess) {
      return [isAndroid() ? 'top-0' : 'top-topBar'];
    }
    return [isAndroid() ? 'top-navBar' : 'top-topAndNavBars'];
  };

  const getSecondTabClasses = () => {
    return featureFlagsStatus.repayment ? ['h-[535px]'] : ['h-[480px]'];
  };

  const getActiveTabClasses = () => {
    if ($settingsStore.activeTab === 1) {
      return ['fixed z-20 mt-auto h-36 w-screen', ...getFirstTabClasses()];
    }
    return ['mt-navBar', ...getSecondTabClasses()];
  };
</script>

<div
  class={[
    ...baseClasses,
    ...getActiveTabClasses(),
    $settingsStore.isGraphExpanded ? 'py-7' : 'py-9',
  ].join(' ')}
  data-cy="bar-graph-section"
>
  <slot />
</div>
