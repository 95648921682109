<script lang="ts">
  import SkeletonLoader from './SkeletonLoader.svelte';
</script>

<div
  class="flex animate-pulse justify-between border-b border-primary-tint90 px-5 py-2"
>
  <SkeletonLoader cssClasses="h-3 rounded-xl w-[40px]" />
  <SkeletonLoader cssClasses="h-3 rounded-xl w-[70px]" />
</div>
{#each { length: 10 } as _}
  <div
    class="mx-5 grid grid-cols-[40px_1fr_80px] space-x-4 border-b border-primary-tint90 pb-4 pt-3"
  >
    <SkeletonLoader cssClasses="[w-40px] h-[40px] rounded-xl" />
    <div>
      <SkeletonLoader cssClasses="h-4 w-11/12 max-w-[200px] rounded-xl" />
      <SkeletonLoader cssClasses="mt-2 h-3 max-w-[240px] rounded-xl" />
    </div>

    <div class="flex flex-col items-end">
      <SkeletonLoader cssClasses="h-4 w-full rounded-xl" />
      <SkeletonLoader cssClasses="h-3 mt-2 w-5/6 rounded-xl" />
    </div>
  </div>
{/each}
