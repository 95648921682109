<script lang="ts">
  import Cactus from '../../assets/cactus.svelte';

  export let label: string;
</script>

<section data-cy="no-invoices-error" class="px-4">
  <Cactus />
  <p
    class="mt-6 whitespace-pre-line text-center text-semantic-muted ios:text-ios-title3"
  >
    {label}
  </p>
</section>
