<script lang="ts">
  export let label: string;
  export let value: string | number;
  export let currencyCode: undefined | string = undefined;
  export let onChange: (value: string) => void;
  export let id = '';
  export let type: string = 'text';
  export let error: string | null = null;

  $: isFilled = value !== '';

  function handleInput(event: Event) {
    const target = event.target as HTMLInputElement;
    onChange(target.value);
  }
</script>

<div class="relative">
  <label
    for={id}
    class={`absolute left-4 top-2 text-android-label-small ios:text-ios-caption ${error? 'text-semantic-danger' : 'text-primary-tint40'}`}>{label}</label>
  <input
    data-cy={id}
    {id}
    {type}
    class={`w-full rounded-md bg-white px-4 pb-2 pt-6 text-android-body-large ios:text-ios-body text-semantic-neutral focus:outline-none !text-base
      ${isFilled ? 'border border-green-base' : ''}
      ${error ? 'border border-semantic-danger': ''}`}
    value={value}
    on:input={handleInput}
  />
  {#if currencyCode}
    <span class="absolute right-4 top-1/2 -translate-y-1/2 text-android-body-large ios:text-ios-body text-semantic-neutral">
      {currencyCode}
    </span>
  {/if}

  {#if error !== null}
    <p class="absolute text-android-label-small ios:text-ios-caption text-highlight-red mt-1">{error}</p>
  {/if}
</div>
