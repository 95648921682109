export const setCookie = (name: string, value: string, days: number = 7) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

export const getCookie = (name: string): string | null => {
  const cookieName = `${name}=`;
  const cookiesArray = document.cookie.split(';');
  const cookie = cookiesArray.find((item) =>
    item.trim().startsWith(cookieName)
  );

  return cookie ? cookie.trim().substring(cookieName.length) : null;
};
