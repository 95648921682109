export const colors = {
  primary: {
    base: '#002D72',
    darken20: '#00245B',
    tint20: '#33578E',
    tint40: '#6681AA',
    tint60: '#99ABC7',
    tint80: '#CCD5E3',
    tint90: '#E6EAF1',
    tint95: '#F2F5F8',
  },
  secondary: {
    base: '#FFED00',
  },
  blue: {
    base: '#0059E4',
    tint20: '#337AE9',
    tint40: '#669BEF',
    tint60: '#99BDF4',
    tint80: '#CCDEFA',
    tint90: '#E5EEFC',
    tint95: '#F2F7FE',
  },
  green: {
    base: '#34C082',
    tint40: '#85D9B4',
    badge: '#D6F2E6',
  },
  red: {
    base: '#DE351B',
    tint40: '#EB8676',
    badge: '#F8D7D1',
  },
  orange: {
    base: '#FF9500',
    badge: '#FFEACC',
  },
  semantic: {
    neutral: '#001432',
    muted: '#33435B',
    inverted: '#FFFFFF',
    invertedMuted: '#CCD0D6',
    appBackground: '#F2F4F8',
    interaction: '#0059E4',
    focused: '#4C8BEC',
    disabled: '#667284',
    attention: '#FFED00',
    success: '#24865B',
    danger: '#DE351B',
    information: '#FF9500',
  },
  highlight: {
    red: '#FF3E2E',
  },
  white: '#FFFFFF',
};
