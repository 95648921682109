import { MPAY_ENV, MPAY_POSTHOG_API_HOST, MPAY_POSTHOG_TOKEN } from '../env';
import posthog from 'posthog-js';
import { getStrippedUrl } from '../lib/utils/helpers';

export class PostHogPluginMissingError extends Error {
  constructor() {
    super('PostHog plugin is missing');
    this.name = 'PostHogPluginMissingError';
  }
}

posthog.init(MPAY_POSTHOG_TOKEN, {
  api_host: MPAY_POSTHOG_API_HOST,
  persistence: 'memory',
  debug: MPAY_ENV === 'local' || MPAY_ENV === 'pre-production',
  autocapture: false,
  disable_cookie: true,
  capture_pageview: false,
  disable_session_recording: true,
  sanitize_properties: (
    properties: Record<string, string>
  ): Record<string, string> => {
    const { $current_url, $initial_referrer, $referrer, url } = properties;
    return {
      ...properties,
      $current_url: getStrippedUrl($current_url),
      $initial_referrer: getStrippedUrl($initial_referrer),
      $referrer: getStrippedUrl($referrer),
      url: getStrippedUrl(url),
    };
  },
});

posthog.register({
  env: MPAY_ENV,
});

posthog.setPersonPropertiesForFlags({'$initial_referring_domain': MPAY_ENV});

export { posthog };
