import { callNative } from './callNative';
import { navigate } from 'svelte-routing';

export function navigateBack(): void {
  if (window.location.pathname === '/') {
    callNative('closeWebView');
    return;
  }

  window.history.back();
}

function navigateTo(route: string): void {
  navigate(route, { replace: false, state: {} });
}

export function registerGlobalNavigation(): void {
  window.navigateBack = navigateBack;
  window.navigateTo = navigateTo;
}
