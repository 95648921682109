<script lang="ts">
  export let cssClasses = '';
</script>

<div class={`${cssClasses} relative overflow-hidden bg-primary-tint80`}>
  <div
    class="shimmer-gradient shimmer absolute bottom-0 left-0 right-0 top-0"
  ></div>
</div>

<style>
  .shimmer-gradient {
    background-image: linear-gradient(
      90deg,
      #ccd5e3 0%,
      #e5eaf1 50%,
      #ccd5e3 100%
    );
  }

  .shimmer {
    transform: translateX(-100%);
    animation: shimmer 1.4s infinite;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
</style>
