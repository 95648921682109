<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.568 4.69924L8.00013 2.47211L2.4323 4.69924C2.43308 4.71059 2.43347 4.72205 2.43347 4.73359V14.0669C2.43347 14.3431 2.20961 14.5669 1.93347 14.5669C1.65732 14.5669 1.43347 14.3431 1.43347 14.0669V5.09878L1.18583 5.19783C0.929437 5.30039 0.638451 5.17568 0.535895 4.91929C0.433338 4.6629 0.558046 4.37191 0.814438 4.26936L7.81444 1.46936C7.93364 1.42167 8.06662 1.42167 8.18583 1.46936L15.1858 4.26936C15.4422 4.37191 15.5669 4.6629 15.4644 4.91929C15.3618 5.17568 15.0708 5.30039 14.8144 5.19783L14.5668 5.09878V14.0669C14.5668 14.3431 14.3429 14.5669 14.0668 14.5669C13.7907 14.5669 13.5668 14.3431 13.5668 14.0669V4.73359C13.5668 4.72205 13.5672 4.71059 13.568 4.69924ZM9.59809 7.45495C9.19911 7.45495 8.79643 7.72135 8.79643 8.31666V11.0741C8.79643 11.6384 8.3962 11.9336 8.0007 11.9336C7.60527 11.9336 7.20496 11.6384 7.20496 11.0741V8.31666C7.20496 7.7198 6.78682 7.44797 6.39836 7.44797C6.00989 7.44797 5.59175 7.7198 5.59175 8.31666V11.0741C5.59175 11.6384 5.19145 11.9336 4.79602 11.9336C4.40045 11.9336 4 11.6384 4 11.0741V6.75488C4 6.21481 4.41375 5.93381 4.82255 5.93381C5.11643 5.93381 5.45969 6.10129 5.51722 6.41468L5.53401 6.50626L5.57747 6.4319C5.76185 6.10172 6.36423 5.93359 7.0008 5.93359C7.7172 5.93359 8.31839 6.22285 8.47811 6.53151L8.50834 6.59988L8.53857 6.53737C8.6767 6.26074 9.33264 5.93381 10.145 5.93381C11.1738 5.93381 12 6.59988 12 7.88059V11.0741C12 11.6384 11.6 11.9336 11.2048 11.9336C10.8097 11.9336 10.4096 11.6384 10.4096 11.0741V8.31666C10.4096 7.72474 9.98892 7.45495 9.59809 7.45495Z"
    fill={color}
  />
</svg>
