<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 18 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.28813 0.75H15.7119C16.8356 0.75 17.75 1.75125 17.75 2.98187V11.0175C17.75 12.2487 16.8356 13.25 15.7119 13.25H2.28813C1.16437 13.25 0.25 12.2487 0.25 11.0175V2.98187C0.25 1.75125 1.16437 0.75 2.28813 0.75ZM2.28813 12H15.7119C16.1463 12 16.5 11.5594 16.5 11.0175V5.21437H1.5V11.0175C1.5 11.5594 1.85312 12 2.28813 12ZM2.28813 2H15.7119C16.1463 2 16.5 2.44062 16.5 2.98187V3.96437H1.5V2.98187C1.5 2.44062 1.85312 2 2.28813 2ZM5.11375 9.23188C5.45937 9.23188 5.73875 8.95188 5.73875 8.60688C5.73875 8.26188 5.45937 7.98188 5.11375 7.98188H2.99438C2.64938 7.98188 2.36938 8.26188 2.36938 8.60688C2.36938 8.95188 2.64938 9.23188 2.99438 9.23188H5.11375ZM9.27125 7C9.27125 7.345 8.99188 7.625 8.64625 7.625H2.99438C2.64938 7.625 2.36938 7.345 2.36938 7C2.36938 6.65438 2.64938 6.375 2.99438 6.375H8.64625C8.99188 6.375 9.27125 6.65438 9.27125 7ZM15.005 7.625C15.3506 7.625 15.63 7.345 15.63 7C15.63 6.65438 15.3506 6.375 15.005 6.375H12.8862C12.5406 6.375 12.2612 6.65438 12.2612 7C12.2612 7.345 12.5406 7.625 12.8862 7.625H15.005Z"
    fill={color}
  />
</svg>
