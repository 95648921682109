<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8 0C12.418 0 16 3.581 16 8C16 12.418 12.418 16 8 16C3.582 16 0 12.418 0 8C0 3.581 3.582 0 8 0ZM8 10.498C7.171 10.498 6.5 11.17 6.5 11.998C6.5 12.827 7.171 13.498 8 13.498C8.829 13.498 9.5 12.827 9.5 11.998C9.5 11.17 8.829 10.498 8 10.498ZM8 2.502C7.448 2.502 7 2.95 7 3.502V8.502C7 9.054 7.448 9.502 8 9.502C8.552 9.502 9 9.054 9 8.502V3.502C9 2.95 8.552 2.502 8 2.502Z"
    fill={color}
  />
</svg>
