<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5669 11.1827L17.86 11.9107C17.7381 12.0362 17.5781 12.0992 17.4181 12.0992C17.2581 12.0992 17.0981 12.0362 16.9763 11.9107C16.7319 11.659 16.7319 11.2516 16.9763 11.0005L17.2413 10.7276L8.68125 1.91229H3.99438C3.64938 1.91229 3.36938 1.62458 3.36938 1.26864C3.36938 0.913353 3.64938 0.625 3.99438 0.625H8.94C9.10625 0.625 9.265 0.693226 9.3825 0.813588L18.5669 10.2726C18.6838 10.3936 18.75 10.5571 18.75 10.7276C18.75 10.8982 18.6838 11.0623 18.5669 11.1827ZM11.3244 19.1864C11.4462 19.3126 11.6063 19.375 11.7663 19.375C11.9263 19.375 12.0863 19.3126 12.2081 19.1864L17.1538 14.0933C17.3981 13.8422 17.3981 13.4348 17.1538 13.1831L7.2625 2.99683C7.145 2.87647 6.98688 2.80824 6.82063 2.80824H1.875C1.52938 2.80824 1.25 3.09659 1.25 3.45189V8.54504C1.25 8.71561 1.31562 8.87974 1.43313 9.0001L11.3244 19.1864ZM11.7663 17.8212L2.5 8.27857V4.09553H6.56188L15.8281 13.6382L11.7663 17.8212ZM6.45312 6.7261C6.45312 7.52873 5.82062 8.17881 5.04125 8.17881C4.26187 8.17881 3.62937 7.52873 3.62937 6.7261C3.62937 5.92348 4.26187 5.2734 5.04125 5.2734C5.82062 5.2734 6.45312 5.92348 6.45312 6.7261Z"
      fill={color}
    />
  </g>
</svg>
