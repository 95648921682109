<script lang="ts">
  export let item: string;
  export let label: string;
  export let group: string | null;
  export let isSelected: boolean;
</script>

<input
  bind:group
  type="radio"
  id={item}
  name="radio"
  value={item}
  class="absolute h-5 w-full cursor-pointer opacity-0"
/>
<div
  class="flex h-5 w-5 items-center justify-center rounded-full border border-blue-base"
>
  <div
    class={`h-3 w-3 transform rounded-full bg-blue-base ${isSelected ? 'scale-100' : 'scale-0'}`}
  ></div>
</div>
<label
  for={item}
  class="ml-3 cursor-pointer text-android-title-medium font-medium text-primary-base ios:text-ios-headline ios:font-semibold"
>
  {label}
</label>
