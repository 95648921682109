import { routes } from '../../routes';
import type { Invoice } from '../../types/mpay.types';

export const isNumber = (value: unknown): value is number =>
  typeof value === 'number';

export const getCurrencyUnit = (currencyCode: string = 'RON') => {
  if (currencyCode === 'LEI' || currencyCode === null) {
    return 'RON';
  }
  return currencyCode;
};

const isValidUrl = (urlString: string): boolean => {
  try {
    new URL(urlString);
    return true;
  } catch {
    return false;
  }
};

export const getStrippedUrl = (url: string): string => {
  if (!url || !isValidUrl(url)) return url;

  const urlObj = new URL(url);
  urlObj.searchParams.delete('accountId');
  urlObj.hash = '';

  return urlObj.toString();
};

// skip invoice screen if pdfLink is missing
export function getNextRoute(invoice: Invoice): string {
  const basePath = invoice.pdfLink
    ? routes.invoice.path
    : routes.invoiceDetails.path;

  return `${basePath}/${invoice.number}`;
}

export const hexToRgb = (hex: string) => {
  let r = 0;
  let g = 0;
  let b = 0;

  // 3 digits
  if (hex.length === 4) {
    r = parseInt(`${hex[1]}${hex[1]}`, 16);
    g = parseInt(`${hex[2]}${hex[2]}`, 16);
    b = parseInt(`${hex[3]}${hex[3]}`, 16);

    // 6 digits
  } else if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  }
  // return rgb value , alert on space
  return `rgb(${r}, ${g}, ${b})`;
};
