<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.79121 3.37014C1.39468 3.74516 1.25 4.08525 1.25 4.37598C1.25 4.68945 1.4196 5.06281 1.89096 5.47182C2.36116 5.87983 3.07546 6.26985 4.00451 6.59801C5.85796 7.25269 8.43707 7.61589 11.2454 7.46768C11.5901 7.44949 11.8843 7.71417 11.9025 8.05887C11.9207 8.40357 11.656 8.69775 11.3113 8.71594C8.3721 8.87106 5.62454 8.49593 3.58819 7.77665C2.57235 7.41783 1.70098 6.96196 1.07172 6.41594C0.44363 5.87092 0 5.18209 0 4.37598C0 3.63087 0.380007 2.9843 0.932308 2.46196C1.48327 1.9409 2.24698 1.5005 3.13884 1.14379C4.92604 0.429005 7.35212 0.000976562 10 0.000976562C12.6479 0.000976562 15.074 0.429005 16.8612 1.14379C17.753 1.5005 18.5167 1.9409 19.0677 2.46196C19.62 2.9843 20 3.63087 20 4.37598C20 5.17051 19.5684 5.85145 18.9548 6.39232C18.3406 6.9338 17.4898 7.38714 16.4977 7.74618C16.1731 7.86364 15.8148 7.69573 15.6973 7.37115C15.5798 7.04658 15.7477 6.68823 16.0723 6.57078C16.9777 6.24314 17.6719 5.8569 18.1283 5.45463C18.5853 5.05176 18.75 4.68477 18.75 4.37598C18.75 4.08525 18.6053 3.74516 18.2088 3.37014C17.8109 2.99387 17.2017 2.62625 16.397 2.30441C14.791 1.66212 12.5296 1.25098 10 1.25098C7.47038 1.25098 5.20896 1.66212 3.60303 2.30441C2.79833 2.62625 2.18907 2.99387 1.79121 3.37014Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.375 3.75098C19.7202 3.75098 20 4.0308 20 4.37598V9.37598C20 9.72115 19.7202 10.001 19.375 10.001C19.0298 10.001 18.75 9.72115 18.75 9.37598V4.37598C18.75 4.0308 19.0298 3.75098 19.375 3.75098Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.625 3.75098C0.970178 3.75098 1.25 4.0308 1.25 4.37598V9.37598C1.25 9.64335 1.37172 9.9505 1.69938 10.2904C2.02989 10.6333 2.54016 10.9755 3.22508 11.286C4.59293 11.9063 6.54259 12.3489 8.78168 12.4685C9.12636 12.4869 9.39086 12.7813 9.37244 13.126C9.35403 13.4707 9.05968 13.7352 8.71499 13.7168C6.36824 13.5914 4.25624 13.1261 2.70887 12.4245C1.93619 12.0741 1.27547 11.6518 0.799399 11.1579C0.320464 10.661 0 10.059 0 9.37598V4.37598C0 4.0308 0.279822 3.75098 0.625 3.75098Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.625 8.75098C0.970178 8.75098 1.25 9.0308 1.25 9.37598V14.376C1.25 14.836 1.63225 15.4302 2.69039 16.0181C3.71103 16.5851 5.19495 17.0442 6.9698 17.2946C7.31159 17.3428 7.54959 17.659 7.50137 18.0008C7.45316 18.3426 7.137 18.5806 6.7952 18.5323C4.92505 18.2685 3.28022 17.7757 2.08336 17.1108C0.924 16.4667 0 15.5493 0 14.376V9.37598C0 9.0308 0.279822 8.75098 0.625 8.75098Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.375 17.501C14.4579 17.501 14.5374 17.4681 14.596 17.4094C14.6546 17.3508 14.6875 17.2714 14.6875 17.1885C14.6875 17.1056 14.6546 17.0261 14.596 16.9675C14.5374 16.9089 14.4579 16.876 14.375 16.876V16.251C14.1264 16.251 13.8879 16.3497 13.7121 16.5256C13.5363 16.7014 13.4375 16.9398 13.4375 17.1885C13.4375 17.4371 13.5363 17.6756 13.7121 17.8514C13.8879 18.0272 14.1264 18.126 14.375 18.126V17.501Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.375 16.251C14.6236 16.251 14.8621 16.3497 15.0379 16.5256C15.2137 16.7014 15.3125 16.9398 15.3125 17.1885C15.3125 17.4371 15.2137 17.6756 15.0379 17.8514C14.8621 18.0272 14.6236 18.126 14.375 18.126V17.501C14.2921 17.501 14.2126 17.4681 14.154 17.4094C14.0954 17.3508 14.0625 17.2714 14.0625 17.1885C14.0625 17.1056 14.0954 17.0261 14.154 16.9675C14.2126 16.9089 14.2921 16.876 14.375 16.876V16.251Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.375 11.876C14.7202 11.876 15 12.1558 15 12.501V15.001C15 15.3462 14.7202 15.626 14.375 15.626C14.0298 15.626 13.75 15.3462 13.75 15.001V12.501C13.75 12.1558 14.0298 11.876 14.375 11.876Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.375 9.99996C14.2165 9.99996 14.0611 10.0441 13.9262 10.1275C13.7914 10.2109 13.6825 10.3301 13.6116 10.4719L10.09 17.5152C10.025 17.6454 9.9943 17.79 10.0009 17.9354C10.0075 18.0808 10.0511 18.2221 10.1276 18.3458C10.2041 18.4696 10.3109 18.5718 10.438 18.6427C10.5651 18.7136 10.7082 18.7509 10.8537 18.751H17.8957C17.8956 18.751 17.8959 18.751 17.8957 18.751C18.0411 18.7507 18.1845 18.7133 18.3114 18.6424C18.4385 18.5715 18.5453 18.4693 18.6218 18.3455C18.6983 18.2217 18.7419 18.0804 18.7486 17.935C18.7552 17.7898 18.7247 17.6452 18.6599 17.5151C18.6599 17.5149 18.66 17.5152 18.6599 17.5151L15.1385 10.4722C15.0676 10.3303 14.9586 10.2109 14.8238 10.1275C14.6889 10.0441 14.5335 9.99996 14.375 9.99996ZM13.2689 9.06429C13.6012 8.85881 13.9843 8.74996 14.375 8.74996C14.7657 8.74996 15.1488 8.85881 15.4811 9.06429C15.8135 9.26978 16.082 9.56378 16.2566 9.91334L19.7786 16.9573C19.9384 17.278 20.0136 17.6341 19.9973 17.992C19.9809 18.35 19.8735 18.6978 19.6851 19.0026C19.4968 19.3074 19.2337 19.559 18.9209 19.7338C18.6081 19.9085 18.2559 20.0004 17.8976 20.001L10.8533 20.001C10.4949 20.0008 10.142 19.909 9.82897 19.7343C9.51592 19.5597 9.25268 19.3079 9.06423 19.003C8.87578 18.698 8.76836 18.35 8.75216 17.9919C8.73596 17.6338 8.81152 17.2775 8.97167 16.9568L12.4934 9.91334C12.4933 9.9134 12.4934 9.91327 12.4934 9.91334C12.668 9.56387 12.9366 9.26974 13.2689 9.06429Z"
      fill={color}
    />
  </g>
</svg>
