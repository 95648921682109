import { getLocaleFromCountryCode } from '../../i18n';
import { isNumber } from './helpers';

export const getFormattedTimestamp = (date: string) =>
  new Intl.DateTimeFormat(getLocaleFromCountryCode(), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC', // BE returns localized date as UTC date
  }).format(new Date(date));

export const getFormattedNumber = (
  value: number | undefined,
  minimumFractionDigits: number = 2
) => {
  if (!isNumber(value)) return;

  if (minimumFractionDigits === 0) {
    const fractionPart = value.toString().split('.')[1];
    if (fractionPart) {
      minimumFractionDigits = 2;
    }
  }

  return value.toLocaleString(getLocaleFromCountryCode(), {
    minimumFractionDigits,
  });
};

export const getFormattedDate = (date: Date | string) => {
  if (!date) return;

  return new Intl.DateTimeFormat(getLocaleFromCountryCode(), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(date));
};
