<script lang="ts">
  import type { IconName } from '../../types/icons.types';
  import Icon from './Icon.svelte';

  export let isDisabled = false;
  export let label: string;
  export let leadingIcon: IconName | null = null;
  export let trailingIcon: IconName | null = null;
  export let onClick: () => void;
  export let id = '';
</script>

<button
  data-cy={id}
  class={`inline-flex items-center justify-center gap-4 rounded py-3 text-android-title-small text-blue-base ios:text-ios-body 
  ${isDisabled ? 'text-semantic-disabled' : 'active:text-blue-tint20'}
`}
  on:click={!isDisabled ? onClick : undefined}
  disabled={isDisabled}
>
  {#if leadingIcon}
    <Icon iconName={leadingIcon} />
  {/if}

  {label}

  {#if trailingIcon}
    <Icon iconName={trailingIcon} />
  {/if}
</button>
