import { IDAM_AUTH } from '../../env';
import {
  ACCOUNT_ID_ITEM,
  COUNTRY_ITEM,
  CountryCode,
  TOKEN_ITEM,
} from '../constants';
// TODO: remove this once we have proper e2e tests
import authFixture from '../../../cypress/fixtures/auth.json';
import { getToken } from './localStorage';
import { jwtDecode } from 'jwt-decode';
import { setCookie } from './cookies';

const setToken = (hash: string) => {
  const params = new URLSearchParams(hash.substring(1)); // remove the '#' from the URL
  const accessToken = params.get('access_token');

  if (accessToken) {
    window.localStorage.setItem(TOKEN_ITEM, accessToken);
  }
};

const getIdamUrl = (): string => {
  const nonce = crypto.randomUUID();
  const baseUrl = IDAM_AUTH.BASE_URL;

  const queryParams = {
    response_type: IDAM_AUTH.RESPONSE_TYPE,
    scope: IDAM_AUTH.SCOPE,
    client_id: IDAM_AUTH.CLIENT_ID,
    realm_id: IDAM_AUTH.REALM_ID,
    redirect_uri: window.location.origin,
    user_type: IDAM_AUTH.USER_TYPE,
    nonce,
  };

  return `${baseUrl}?${new URLSearchParams(queryParams)}`;
};

// Authenticates the logged in user in an in-app browser flow based on credentials passed from the app
export const authenticateUser = (): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const authUrl = new URL(window.location.href);

    if (authUrl.searchParams.has('error')) {
      reject(
        new Error(
          'Authentication Error: ' +
            authUrl.searchParams.get('error_description')
        )
      );
      return;
    }

    const country = authUrl.searchParams.get(COUNTRY_ITEM) || CountryCode.RO;
    window.localStorage.setItem(COUNTRY_ITEM, country);
    setCookie(COUNTRY_ITEM, country);

    const accountId =
      [...authUrl.searchParams.entries()].find(
        ([key]) => key.toLowerCase() === ACCOUNT_ID_ITEM.toLowerCase()
      )?.[1] ||
      (authUrl.hash ? null : window.localStorage.getItem(ACCOUNT_ID_ITEM));

    if (accountId) {
      if (import.meta.env.MODE === 'test') {
        window.localStorage.setItem(ACCOUNT_ID_ITEM, accountId);
        window.location.replace(
          `http://localhost:8080#access_token=${authFixture.accessToken}`
        );
        return;
      }

      // we set the accountId as it's the only time we will get it (from the app's credentials)
      window.localStorage.setItem(ACCOUNT_ID_ITEM, accountId);
      setCookie(ACCOUNT_ID_ITEM, accountId);
      window.location.replace(getIdamUrl());
      return;
    }

    if (authUrl.hash.length > 0) {
      setToken(authUrl.hash);
      resolve();
    }

    reject(new Error('Authentication Error: No identifiers found'));
  });

export const isTokenValid = async (): Promise<boolean> => {
  const token = getToken();

  if (token) {
    const userData: { exp: number } = await jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return userData.exp > currentTime;
  }

  return false;
};
