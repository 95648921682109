<script lang="ts">
  import { getRippleEffect } from '../utils/animations';
  import Icon from './Icon.svelte';

  export let isActive: boolean = false;
  export let withChevron: boolean = false;
  export let id = '';

  const createRipple = (event: MouseEvent) => {
    const { circle } = getRippleEffect(event);
    circle.classList.add('bg-primary-tint80');
  };
</script>

<button
  class={[
    'relative inline-flex h-8 w-fit items-center gap-2 overflow-hidden rounded-2xl border border-primary-base px-3 py-2 text-android-label-medium text-primary-base ios:gap-1 ios:text-ios-caption ios:font-semibold',
    isActive ? 'bg-primary-tint80/60' : '',
  ].join(' ')}
  data-cy={id}
  on:click
  on:mousedown={createRipple}
>
  {#if isActive}
    <div
      class={[
        'transition-all duration-300',
        isActive ? 'w-4 opacity-100' : 'w-0 opacity-0',
      ].join(' ')}
    >
      <Icon iconName="CheckmarkCircle" size="16" />
    </div>
  {/if}

  <slot />

  {#if withChevron}
    <Icon iconName="ChevronDown" size="10" />
  {/if}
</button>
