<script lang="ts">
  import { t } from 'svelte-i18n';
  import Chip from './Chip.svelte';
  import { invoicesStore } from '../../store/invoicesStore';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import { Target } from '../utils/analytics/keys';
  import { topBar } from '../../store/TopBarStore';

  export let onSelect: (...args: unknown[]) => unknown;
  $: myInvoices = $invoicesStore.filters.myInvoices;

  const setShowMyInvoices = async () => {
    invoicesStore.setMyInvoicesFilter();

    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: $topBar.context,
      target: Target.MyInvoices,
    });

    onSelect();
  };
</script>

<Chip
  id="my-invoices-button"
  on:click={setShowMyInvoices}
  isActive={myInvoices}
>
  <p class="truncate">
    {$t('invoices.my_invoices')}
  </p>
</Chip>
