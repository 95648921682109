<script lang="ts">
  import { getCurrencyUnit } from '../utils/helpers';

  export let title: string;
  export let value: string | undefined = undefined;
  export let showCurrency = true;
  export let className: string | undefined = undefined;
  export let currencyCode: string | undefined = undefined;
</script>

<section class="flex flex-col items-center">
  <p
    class="text-primary-tint95 {className === undefined
      ? 'text-android-headline-small ios:text-ios-title1'
      : className}"
  >
    {value === undefined ? '--' : value}

    {#if showCurrency}
      <span
        class={className === undefined
          ? 'text-android-title-medium ios:text-ios-title3'
          : className}
      >
        {getCurrencyUnit(currencyCode)}
      </span>
    {/if}
  </p>

  <h2
    class="text-android-label-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
  >
    {title}
  </h2>
</section>
