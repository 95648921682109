<script lang="ts">
  import { t } from 'svelte-i18n';
  import { bankLogos, type BankName } from '../utils/banks';
  import Icon from './Icon.svelte';

  export let name: BankName;
  export let onClick: (name: BankName) => void;
</script>

<li
  class="border-b border-primary-tint90 px-5 last:border-0 active:bg-primary-tint90"
>
  <button
    on:click={() => onClick(name)}
    class="flex w-full items-center justify-between gap-3 pb-[15px] pt-[17px]"
  >
    <div
      class="flex h-[40px] min-w-[40px] items-center justify-center rounded-lg bg-semantic-inverted p-2 text-semantic-neutral shadow-small"
    >
      <img src={bankLogos[name]} alt="logo" height="30" width="30" />
    </div>

    <p
      class="flex w-full truncate text-android-title-medium font-medium text-primary-base ios:text-ios-headline ios:font-semibold"
    >
      {$t(`banks.${name}`)}
    </p>

    <div class="flex items-center justify-center text-semantic-muted">
      <Icon iconName="ChevronRight" />
    </div>
  </button>
</li>
