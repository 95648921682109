import { Context } from './lib/utils/analytics/keys';

export const routes = {
  home: {
    path: '/',
    name: Context.Home,
  },
  invoice: {
    path: '/invoice',
    name: Context.Invoice,
  },
  invoiceDetails: {
    path: '/invoice-details',
    name: Context.InvoiceDetails,
  },
  repayment: {
    path: '/repayment',
    name: Context.Repayment,
  },
  repaymentBankSelection: {
    path: '/repayment/bank-selection',
    name: Context.BankSelection,
  },
};
