import { jwtDecode } from 'jwt-decode';
import posthog from 'posthog-js';
import { getToken } from './localStorage';

async function hashEmailWithSha256(email: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  return Array.from(new Uint8Array(hashBuffer))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
}

export async function setPosthogUniqueId() {
  const token = getToken();

  if (token) {
    try {
      const userData: { email: string } = await jwtDecode(token);
      const uniqueId = await hashEmailWithSha256(userData.email);

      posthog.identify(uniqueId);
    } catch {
      throw new Error('Invalid token');
    }
  }
}
