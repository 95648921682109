<script lang="ts">
  import { t } from 'svelte-i18n';
  import LinkButton from './LinkButton.svelte';
  import { fade, slide } from 'svelte/transition';
  import { pan, type PanCustomEvent } from 'svelte-gestures';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import { onDestroy } from 'svelte';

  export let isOpen: boolean;
  export let setIsOpen: () => void;
  export let title: string | undefined = undefined;
  export let testId: string | undefined = undefined;
  export let showCloseButton = true;
  export let tinted = false;

  let startY: number | null = null;
  let isPointerDown = false;

  const translateY = tweened(0, {
    duration: 400,
    easing: cubicOut,
  });

  function handlePan(event: PanCustomEvent) {
    const { y } = event.detail;

    if (startY === null) {
      startY = y;
      return;
    }

    const deltaY = y - startY;
    translateY.set(deltaY);

    if (deltaY > 100) {
      setIsOpen();
      handlePointerUp();
    } else if (!isPointerDown) {
      handlePointerUp();
    }
  }

  function handlePointerDown() {
    isPointerDown = true;
  }

  function handlePointerUp() {
    isPointerDown = false;
    startY = null;
    translateY.set(0);
  }

  onDestroy(handlePointerUp);
</script>

{#if isOpen}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    data-cy={testId}
    transition:fade={{ duration: 300 }}
    class={isOpen
      ? 'absolute bottom-0 left-0 right-0 top-0 z-50 h-full bg-semantic-neutral/30'
      : ''}
    on:click={setIsOpen}
  />

  <!-- show bg if users pan the sheet up -->
  <div
    transition:slide={{ duration: 300 }}
    class="
      absolute bottom-0 left-0 right-0 z-50 h-52
      {isOpen ? 'translate-y-0' : 'translate-y-full'}
      {tinted ? 'bg-semantic-appBackground' : 'bg-white'}
    "
  />

  <div
    use:pan={{ delay: 0 }}
    on:pan={handlePan}
    on:pointerdown={handlePointerDown}
    on:pointerup={handlePointerUp}
    on:pointercancel={handlePointerUp}
    on:pointerleave={handlePointerUp}
    transition:slide={{ duration: 300 }}
    class={[
      `fixed bottom-0 left-0 right-0 z-50 flex h-fit flex-col items-center rounded-t-3xl pb-6 pt-4`,
      `${isOpen ? 'translate-y-0' : 'translate-y-full'}`,
      `${tinted ? 'bg-semantic-appBackground' : 'bg-white'}`,
    ].join(' ')}
    style="transform: translateY({$translateY}px)"
  >
    <button
      class="h-1 w-10 rounded-xl bg-primary-tint90"
      on:click={setIsOpen}
    />

    {#if title && showCloseButton}
      <div class="mt-2 flex w-full items-center justify-between px-5">
        <h3
          data-cy="bottom-sheet-title"
          class="text-android-title-large text-semantic-neutral ios:text-ios-title2 ios:font-semibold"
        >
          {title}
        </h3>
        <LinkButton
          id="bottom-sheet-cancel-button"
          label={$t('general.cta.cancel')}
          onClick={setIsOpen}
        />
      </div>
    {/if}

    <div class="w-full touch-none px-5 pt-2">
      <slot />
    </div>
  </div>
{/if}
