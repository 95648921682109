import { writable } from 'svelte/store';
import { MPAY_API_BASE_URI } from '../env';
import { MPayApi } from '../lib/constants';
import { sendRequest } from '../lib/utils/fetch';
import { getAccountId, getCountry } from '../lib/utils/localStorage';
import type { BankName } from '../lib/utils/banks';

type Settings = {
  activeTab: number;
  isGraphExpanded: boolean;
  accountDetails: {
    cardHolderId: string;
    mcreditAccess: boolean;
  };
  selectedBank: BankName | null;
};

const initialState: Settings = {
  activeTab: 1,
  isGraphExpanded: false,
  accountDetails: {
    cardHolderId: '',
    mcreditAccess: false,
  },
  selectedBank: null,
};

const createSettingsStore = () => {
  const { subscribe, update } = writable(initialState);

  return {
    subscribe,
    setActiveTab: (value: number) =>
      update((state) => ({ ...state, activeTab: value })),
    setIsGraphExpanded: (value: boolean) =>
      update((state) => ({ ...state, isGraphExpanded: value })),
    setAccountDetails: (value: Settings['accountDetails']) => {
      if (!value.mcreditAccess) {
        update((state) => ({ ...state, activeTab: 1 }));
      }
      update((state) => ({ ...state, accountDetails: value }));
    },
    setSelectedBankAccount: (value: Settings['selectedBank']) =>
      update((state) => ({ ...state, selectedBank: value })),
  };
};

export const settingsStore = createSettingsStore();

export const fetchAccountDetails = async () => {
  try {
    const url = `${MPAY_API_BASE_URI}/${MPayApi.PERSON_DETAILS}/${getCountry()}?accountId=${getAccountId()}`;
    const response = await sendRequest<Settings['accountDetails']>(url);

    settingsStore.setAccountDetails(response);
  } catch (error) {
    console.error(error);
  }
};
