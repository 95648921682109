<script lang="ts">
  import SkeletonLoader from './SkeletonLoader.svelte';
  import Layout from '../layouts/main.svelte';
</script>

<Layout>
  <div class="mb-8 bg-primary-darken20 px-5 pb-6 pt-8">
    <SkeletonLoader cssClasses="rounded-xl w-[300px] h-9 mb-6" />
    <SkeletonLoader cssClasses="rounded-xl w-full h-14 mb-6" />
    <SkeletonLoader cssClasses="rounded-xl w-full h-10" />
  </div>
  <div class="px-5">
    <SkeletonLoader cssClasses="rounded-xl w-[300px] h-6 mb-8" />
    {#each { length: 4 } as _}
      <div
        class="flex h-[52px] items-center justify-between border-b border-primary-tint90"
      >
        <SkeletonLoader cssClasses="h-4 w-20 rounded-xl" />
        <SkeletonLoader cssClasses="h-4 w-28 rounded-xl" />
      </div>
    {/each}
  </div>
</Layout>
