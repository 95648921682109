<script lang="ts">
  import Layout from '../layouts/main.svelte';
  import { posthog } from '../../plugins/posthog';
  import NavigationTabs from '../components/NavigationTabs.svelte';
  import { settingsStore } from '../../store/settingsStore';
  import Invoices from '../components/Invoices.svelte';
  import SpendGraphSection from '../components/SpendGraphSection.svelte';
  import { fly, fade } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';
  import MCredit from '../components/MCredit.svelte';
  import NoInvoices from '../components/NoInvoices.svelte';
  import { t } from 'svelte-i18n';
  import MCreditInvoices from '../components/MCreditInvoices.svelte';
  import AnimatedBlueBackdrop from '../layouts/animatedBlueBackdrop.svelte';

  export let hasAuthError = false;

  posthog.capture('$pageview');

  const leftToRightDelayTrans = {
    delay: 500,
    duration: 500,
    x: -300,
    opacity: 0,
    easing: quintOut,
  };

  const rightToLeftDelayTrans = {
    delay: 500,
    duration: 500,
    x: 300,
    opacity: 0,
    easing: quintOut,
  };

  const leftToRightTrans = {
    delay: 0,
    duration: 500,
    x: 300,
    opacity: 0,
    easing: quintOut,
  };

  function onAnimationStart() {
    document.body.style.overflow = 'hidden';
  }

  function onAnimationEnd() {
    document.body.style.overflow = 'auto';
  }
</script>

<Layout>
  {#if hasAuthError}
    <div class="mt-6">
      <NoInvoices label={$t('error.auth_failure')} />
    </div>
  {:else}
    {#if $settingsStore.accountDetails.mcreditAccess}
      <NavigationTabs />
    {/if}

    <AnimatedBlueBackdrop>
      {#if $settingsStore.activeTab === 1}
        <div
          class={[
            'transition-all duration-300',
            $settingsStore.isGraphExpanded ? 'h-[88px]' : 'h-[72px]',
          ].join(' ')}
          in:fly={leftToRightDelayTrans}
          out:fade={{ duration: 500 }}
          on:introend={onAnimationEnd}
          on:outrostart={onAnimationStart}
        >
          <SpendGraphSection />
        </div>
      {:else}
        <div
          in:fly={rightToLeftDelayTrans}
          out:fly={leftToRightTrans}
          on:introend={onAnimationEnd}
          on:outrostart={onAnimationStart}
        >
          <MCredit />
        </div>
      {/if}
    </AnimatedBlueBackdrop>

    {#if $settingsStore.activeTab === 1}
      <div
        class={[
          $settingsStore.accountDetails.mcreditAccess
            ? 'pt-[191px]'
            : 'pt-[143px]',
        ].join(' ')}
        in:fly={leftToRightDelayTrans}
        out:fade={{ duration: 500 }}
        on:introend={onAnimationEnd}
        on:outrostart={onAnimationStart}
      >
        <Invoices />
      </div>
    {/if}

    {#if $settingsStore.accountDetails.mcreditAccess && $settingsStore.activeTab === 2}
      <div
        in:fly={rightToLeftDelayTrans}
        out:fade={{ duration: 500 }}
        on:introend={onAnimationEnd}
        on:outrostart={onAnimationStart}
      >
        <MCreditInvoices />
      </div>
    {/if}
  {/if}
</Layout>
