<script lang="ts">
  import { t } from 'svelte-i18n';
  import Button from './Button.svelte';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import { CARD_CODE, paymentCodes } from '../constants';
  import BottomSheet from './BottomSheet.svelte';
  import Switch from './Switch.svelte';
  import { getPaymentMethodLocalisedString } from '../utils/paymentCodes';
  import { invoicesStore } from '../../store/invoicesStore';

  // the most common payment methods
  const paymentCodesOptions = [
    paymentCodes[1],
    paymentCodes[300], // 300, 301, 380, 400
    paymentCodes[206],
    paymentCodes[600],
    'other',
  ];
  const additionalCardValues = ['301', '380', '400'];

  export let isOpen: boolean;
  export let setIsOpen: () => void;
  export let onSelect: (values: string[] | []) => void;

  let selectedValues: string[] = $invoicesStore.filters.paymentMethod;

  const otherOptions = Object.values(paymentCodes).filter(
    (code) =>
      !paymentCodesOptions.includes(code.toString()) &&
      !additionalCardValues.includes(code.toString())
  );

  const toggleOption = (option: string) => {
    if (selectedValues.includes(option)) {
      selectedValues = selectedValues.filter((value) => value !== option);
    } else {
      selectedValues = [...selectedValues, option];
    }
  };

  const setSelection = (values: string[]) => {
    trackEvents(EventsMapKeys.PAYMENT_METHOD_FILTER, values);
    invoicesStore.setPaymentMethodFilter(values);
    onSelect(values);
  };

  const onSaveSelection = () => {
    let options: string[] = [];
    if (selectedValues.includes('other')) {
      const selectedOptions = selectedValues.filter(
        (value) => value !== 'other'
      );
      options = [...selectedOptions, ...otherOptions];
    } else {
      options = [...selectedValues];
    }

    // usecase for additionalCardValues
    if (selectedValues.includes(CARD_CODE)) {
      options = [...options, ...additionalCardValues];
    }

    setSelection(options);
  };

  const onResetSelection = () => {
    selectedValues = [];
    setSelection(selectedValues);
  };
</script>

<BottomSheet
  testId="payment-method-filter"
  {isOpen}
  {setIsOpen}
  title={$t('bottom_sheet_payment_method.headline')}
>
  <ul data-cy="payment-types-list" class="mb-4">
    {#each paymentCodesOptions as option}
      <li
        class="relative flex items-center justify-between border-b border-b-primary-tint90 py-4 text-android-title-medium last:border-0 ios:text-ios-headline ios:font-semibold"
      >
        <Switch
          item={option}
          label={getPaymentMethodLocalisedString(option)}
          isChecked={selectedValues.includes(option)}
          onChange={toggleOption}
        />
      </li>
    {/each}
  </ul>

  <div
    class="flex w-full justify-between gap-3 border-t border-t-primary-tint90 pt-4"
  >
    <Button
      type="outlined"
      onClick={onResetSelection}
      label={$t('general.cta.reset')}
      id="clear-payment-filter"
    />
    <Button
      onClick={onSaveSelection}
      label={$t('general.cta.save')}
      id="save-payment-filter"
    />
  </div>
</BottomSheet>
