import { writable } from 'svelte/store';
import type { InvoicesSummary, MonthlyInvoices } from '../types/mpay.types';
import type { Pagination } from '../types/globals.types';
import { DateRange } from '../lib/constants';

const initialState: {
  invoices: MonthlyInvoices;
  pagination: Pagination;
  currentPage: number;
  filters: {
    myInvoices: boolean;
    dateRange: DateRange;
    paymentMethod: string[] | [];
  };
  summary: InvoicesSummary[];
} = {
  invoices: {},
  pagination: {
    currentPage: 0,
    totalPages: 0,
    total: 0,
  },
  currentPage: 1,
  filters: {
    myInvoices: false,
    dateRange: DateRange.THIS_AND_LAST_YEAR,
    paymentMethod: [],
  },
  summary: [
    {
      month: 0,
      year: 0,
      net: 0,
      gross: 0,
      currencyCode: 'RON',
    },
  ],
};

const createInvoicesStore = () => {
  const { subscribe, update } = writable(initialState);

  return {
    subscribe,
    setInvoices: (data: {
      invoices: MonthlyInvoices;
      pagination: Pagination;
    }) =>
      update((state) => ({
        ...state,
        ...data,
      })),
    setSummary: (value: InvoicesSummary[]) =>
      update((state) => ({
        ...state,
        summary: value,
      })),
    setCurrentPage: (value: number) =>
      update((state) => ({
        ...state,
        currentPage: value,
      })),
    // filters update
    setMyInvoicesFilter: () =>
      update((state) => ({
        ...state,
        currentPage: 1,
        filters: {
          ...state.filters,
          myInvoices: !state.filters.myInvoices,
        },
      })),
    setDateRangeFilter: (value: DateRange) =>
      update((state) => ({
        ...state,
        currentPage: 1,
        filters: {
          ...state.filters,
          dateRange: value,
        },
      })),
    setPaymentMethodFilter: (value: string[]) =>
      update((state) => ({
        ...state,
        currentPage: 1,
        filters: {
          ...state.filters,
          paymentMethod: value,
        },
      })),
    resetInvoices: () =>
      update((state) => ({
        ...state,
        invoices: {},
        pagination: {
          currentPage: 0,
          totalPages: 0,
          total: 0,
        },
      })),
  };
};

export const invoicesStore = createInvoicesStore();
