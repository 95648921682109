<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5825 1.66625H17.2906C17.6356 1.66625 17.9156 1.94625 17.9156 2.29125V7.91562C17.9156 8.26125 17.6356 8.54062 17.2906 8.54062C16.945 8.54062 16.6656 8.26125 16.6656 7.91562V7.0825H1.25V14.3738H9.16625C9.51125 14.3738 9.79125 14.6538 9.79125 14.9988C9.79125 15.3438 9.51125 15.6238 9.16625 15.6238H0.625C0.28 15.6238 0 15.3438 0 14.9988V2.29125C0 1.94625 0.28 1.66625 0.625 1.66625H3.33313V0.625C3.33313 0.28 3.61313 0 3.95813 0H6.45813C6.80313 0 7.08313 0.28 7.08313 0.625V1.66625H10.8331V0.625C10.8331 0.28 11.1125 0 11.4581 0H13.9575C14.3025 0 14.5825 0.28 14.5825 0.625V1.66625ZM1.25006 2.91622V5.83247H16.6657V2.91622H14.5826V3.9581C14.5826 4.3031 14.3026 4.5831 13.9576 4.5831H11.4582C11.1126 4.5831 10.8332 4.3031 10.8332 3.9581V2.91622H7.08319V3.9581C7.08319 4.3031 6.80319 4.5831 6.45819 4.5831H3.95819C3.61319 4.5831 3.33319 4.3031 3.33319 3.9581V2.91622H1.25006ZM4.58319 3.3331H5.83256V1.24998H4.58319V3.3331ZM13.3326 3.3331H12.0832V1.24998H13.3326V3.3331ZM19.8176 11.6087L17.8469 9.63873C17.6032 9.39435 17.2076 9.39435 16.9632 9.63873L11.0519 15.55C10.9944 15.6075 10.9488 15.6756 10.9169 15.7512L10.9144 15.7569V15.7575L9.50444 19.1344C9.40631 19.3681 9.45944 19.6375 9.63881 19.8169C9.75881 19.9362 9.91881 20 10.0807 20C10.1619 20 10.2438 19.9837 10.3219 19.9519L13.7051 18.5387C13.7801 18.5075 13.8488 18.4612 13.9063 18.4037L19.8176 12.4925C19.9344 12.375 20.0001 12.2162 20.0001 12.0506C20.0001 11.8844 19.9344 11.7256 19.8176 11.6087ZM16.7132 11.6562L17.7994 12.7425L18.4913 12.0506L17.4051 10.9644L16.7132 11.6562ZM11.7113 17.0931L12.3632 17.7443L11.2444 18.2118L11.7113 17.0931ZM12.3776 15.9919L13.4638 17.0781L16.9157 13.6262L15.8294 12.54L12.3776 15.9919Z"
      fill={color}
    />
  </g>
</svg>
