<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.39743 1.42919C7.22705 0.651615 9.29063 0.632706 11.1342 1.37662C12.9778 2.12053 14.4503 3.56633 15.2279 5.39596C16.0055 7.22559 16.0244 9.28917 15.2804 11.1327C14.9727 11.8955 14.5447 12.5947 14.0192 13.2075L18.9984 18.1874C19.2221 18.4111 19.2221 18.7739 18.9983 18.9976C18.7746 19.2213 18.4118 19.2213 18.1881 18.9975L13.209 14.0177C12.6308 14.5136 11.9752 14.9229 11.2611 15.2264C9.43148 16.004 7.3679 16.0229 5.52433 15.279C3.68077 14.5351 2.20823 13.0893 1.43065 11.2596C0.65308 9.43002 0.63417 7.36644 1.37808 5.52287C2.122 3.6793 3.5678 2.20676 5.39743 1.42919ZM12.8379 12.7993C12.8315 12.8052 12.8252 12.8112 12.819 12.8174C12.8128 12.8236 12.8067 12.8299 12.8009 12.8363C12.2343 13.3983 11.5616 13.8538 10.813 14.1719C9.263 14.8306 7.51486 14.8466 5.95309 14.2164C4.39133 13.5862 3.14388 12.3614 2.48516 10.8115C1.82645 9.26153 1.81043 7.51339 2.44063 5.95163C3.07083 4.38986 4.29563 3.14241 5.84558 2.4837C7.39553 1.82498 9.14368 1.80897 10.7054 2.43917C12.2672 3.06937 13.5147 4.29417 14.1734 5.84412C14.8321 7.39407 14.8481 9.14221 14.2179 10.704C13.8987 11.4949 13.4271 12.2053 12.8379 12.7993Z"
    fill={color}
  />
</svg>
