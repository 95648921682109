const rippleCleaner = {
  timeoutID: 0,

  setup() {
    if (this.timeoutID) {
      this.cancel();
    }

    this.timeoutID = Number(setTimeout(() => this._cleanRippleEffect(), 500));
  },

  _cleanRippleEffect() {
    const ripple = document.getElementsByClassName('ripple');
    if (ripple) {
      while (ripple.length > 0) {
        ripple[0].parentNode?.removeChild(ripple[0]);
      }
    }
  },

  cancel() {
    clearTimeout(this.timeoutID);
  },
};

export const getRippleEffect = (e: MouseEvent) => {
  const button = e.currentTarget as HTMLButtonElement;
  const circle = document.createElement('span');
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${e.clientX - button.offsetLeft - radius}px`;
  circle.style.top = `${e.clientY - button.offsetTop - radius}px`;
  circle.classList.add('ripple');

  rippleCleaner.setup();

  button.appendChild(circle);

  return {
    button,
    circle,
  };
};
