import alpha_ro from '../../assets/images/banks/alpha_ro.png';
import bcr_ro from '../../assets/images/banks/bcr_ro.png';
import brd_ro from '../../assets/images/banks/brd_ro.png';
import bt_ro from '../../assets/images/banks/bt_ro.png';
import cec_ro from '../../assets/images/banks/cec_ro.png';
import exim_ro from '../../assets/images/banks/exim_ro.png';
import first_ro from '../../assets/images/banks/first_ro.png';
import garanti_ro from '../../assets/images/banks/garanti_ro.png';
import ing_ro from '../../assets/images/banks/ing_ro.png';
import intesa_ro from '../../assets/images/banks/intesa_ro.png';
import libra_ro from '../../assets/images/banks/libra_ro.png';
import otp_ro from '../../assets/images/banks/otp_ro.png';
import patria_ro from '../../assets/images/banks/patria_ro.png';
import revolut_ro from '../../assets/images/banks/revolut_ro.png';
import rzb_ro from '../../assets/images/banks/rzb_ro.png';
import salt_ro from '../../assets/images/banks/salt_ro.png';
import unicredit_ro from '../../assets/images/banks/unicredit_ro.png';
import vista_ro from '../../assets/images/banks/vista_ro.png';

export const bankLogos = {
  alpha_ro,
  bcr_ro,
  brd_ro,
  bt_ro,
  cec_ro,
  exim_ro,
  first_ro,
  garanti_ro,
  ing_ro,
  intesa_ro,
  libra_ro,
  otp_ro,
  patria_ro,
  revolut_ro,
  rzb_ro,
  salt_ro,
  unicredit_ro,
  vista_ro,
};

export type BankName = keyof typeof bankLogos;

type BankRecord = {
  name: BankName;
};

export const banks: BankRecord[] = [
  {
    name: 'garanti_ro',
  },
  {
    name: 'libra_ro',
  },
  {
    name: 'ing_ro',
  },
  {
    name: 'otp_ro',
  },
  {
    name: 'salt_ro',
  },
  {
    name: 'cec_ro',
  },
  {
    name: 'revolut_ro',
  },
  {
    name: 'intesa_ro',
  },
  {
    name: 'first_ro',
  },
  {
    name: 'rzb_ro',
  },
  {
    name: 'unicredit_ro',
  },
  {
    name: 'vista_ro',
  },
  {
    name: 'exim_ro',
  },
  {
    name: 'alpha_ro',
  },
  {
    name: 'bcr_ro',
  },
  {
    name: 'brd_ro',
  },
  {
    name: 'patria_ro',
  },
  {
    name: 'bt_ro',
  },
];
