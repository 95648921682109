import {
  register,
  init,
  getLocaleFromNavigator,
  getLocaleFromQueryString,
} from 'svelte-i18n';
import { COUNTRY_ITEM } from './lib/constants';

export const locales = {
  en: {
    translationKey: 'en',
    dateLocale: 'en-US',
  },
  ro: {
    translationKey: 'ro',
    dateLocale: 'ro-RO',
  },
};

type LocaleKey = keyof typeof locales;

export const getLocale = (): string => {
  const queryLocale = getLocaleFromQueryString('lang');
  const navLocale = getLocaleFromNavigator();

  if (queryLocale !== null && queryLocale !== undefined) {
    if (queryLocale in locales) {
      return locales[queryLocale as LocaleKey].dateLocale;
    }
  }

  if (
    navLocale &&
    Object.values(locales).find((locale) => navLocale === locale.dateLocale)
  ) {
    return navLocale;
  }

  // for the case if and when navigator is overwritten manually with 'xx' format instead of browser's 'xx-XX'
  if (navLocale && navLocale in locales) {
    return locales[navLocale as LocaleKey].dateLocale;
  }

  return locales.en.dateLocale;
};

export const getLocaleFromCountryCode = () => {
  const countryCode = localStorage.getItem(COUNTRY_ITEM);

  if (countryCode && countryCode in locales) {
    return locales[countryCode as LocaleKey].dateLocale;
  }
  return locales.ro.dateLocale;
};

register(locales.en.translationKey, () => import('./lib/i18n/en.json'));
register(locales.ro.translationKey, () => import('./lib/i18n/ro.json'));

init({
  fallbackLocale: locales.en.translationKey,
  initialLocale: getLocale(),
});
