import { t } from 'svelte-i18n';
import { get } from 'svelte/store';
import type { Payment } from '../../types/mpay.types';

export const getPaymentMethodLocalisedString = (
  paymentCode: number | string
): string => {
  if (!paymentCode) return get(t)('general.unknown');

  switch (paymentCode.toString()) {
    case '1':
      return get(t)('paymentType.cash');
    case '200':
      return get(t)('paymentType.cec');
    case '205':
      return get(t)('paymentType.sponsorship');
    case '206':
      return get(t)('paymentType.paymentOrder');
    case '207':
      return get(t)('paymentType.equipmentsRates');
    case '208':
      return get(t)('paymentType.cashDeposit');
    case '209':
      return get(t)('paymentType.storno');
    case '300':
    case '301':
    case '380':
    case '400':
      return get(t)('paymentType.card');
    case '600':
      return get(t)('paymentType.mCredit');
    case '602':
      return get(t)('paymentType.internalConsumption');
    case '700':
      return get(t)('paymentType.transportGuarantees');
    case '800':
    case '801':
    case '1500':
      return get(t)('paymentType.packagingVoucher');
    case '803':
      return get(t)('paymentType.srgVoucher');
    case '810':
    case '811':
      return get(t)('paymentType.returnVoucher');
    case '900':
    case '902':
    case '903':
      return get(t)('paymentType.giftVoucher');
    case '901':
      return get(t)('paymentType.discount');
    case '904':
    case '905':
    case '1000':
      return get(t)('paymentType.voucher');
    case '920':
      return get(t)('paymentType.mealTicket');
    case '950':
      return get(t)('paymentType.mPlusPoints');
    case '952':
      return get(t)('paymentType.loyaltyPoints');
    case '1200':
      return get(t)('paymentType.compensation');
    case '1300':
      return get(t)('paymentType.treeParties');
    case 'other':
    default:
      return get(t)('paymentType.other');
  }
};

export const getPaymentMethodDescription = (paymentList: Payment[]): string => {
  return paymentList.length > 1
    ? get(t)('paymentType.multiple')
    : getPaymentMethodLocalisedString(paymentList[0]?.paymentCode);
};
