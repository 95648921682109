<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.7334 9.75493C13.7334 10.1041 13.5838 10.4035 13.3343 10.603L7.79674 15.5419C7.39763 15.8413 6.79897 15.7914 6.49965 15.3923C6.20032 14.9932 6.20032 14.4444 6.59942 14.1451L11.4885 9.8547C11.5383 9.80482 11.5383 9.75493 11.4885 9.65515L6.59942 5.36478C6.20032 5.01556 6.15043 4.46679 6.49965 4.06768C6.84886 3.66858 7.39763 3.61869 7.79674 3.96791C7.79674 3.96791 7.79674 3.96791 7.84662 4.0178L13.3842 8.90683C13.5838 9.10638 13.7334 9.4556 13.7334 9.75493Z"
    fill={color}
  />
</svg>
