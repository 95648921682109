<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 12 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.073 9.81733C11.4205 10.1649 11.4205 10.7266 11.073 11.0742C10.8996 11.2475 10.6721 11.3346 10.4445 11.3346C10.217 11.3346 9.98944 11.2475 9.81611 11.0742L6.00019 7.25828L2.18427 11.0742C2.01094 11.2475 1.78339 11.3346 1.55584 11.3346C1.32829 11.3346 1.10074 11.2475 0.927409 11.0742C0.579861 10.7266 0.579861 10.1649 0.927409 9.81733L4.74333 6.00141L0.927409 2.18549C0.579861 1.83794 0.579861 1.27618 0.927409 0.92863C1.27496 0.581082 1.83672 0.581082 2.18427 0.92863L6.00019 4.74455L9.81611 0.92863C10.1637 0.581082 10.7254 0.581082 11.073 0.92863C11.4205 1.27618 11.4205 1.83794 11.073 2.18549L7.25705 6.00141L11.073 9.81733Z"
    fill={color}
  />
</svg>
