<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  fill={color}
  viewBox="0 0 16 16"
  xmlns="http://www.w3.org/2000/svg"
  data-cy="checkmark-circle-icon"
>
  <path
    d="M12.32 4.3C12.1418 4.16982 11.9194 4.11503 11.7011 4.14747C11.4828 4.1799 11.286 4.29696 11.1533 4.47333L7.20667 9.80667L4.66667 7.82667C4.58205 7.75216 4.48319 7.6956 4.37608 7.66041C4.26897 7.62522 4.15583 7.61214 4.04351 7.62194C3.9312 7.63174 3.82204 7.66424 3.72264 7.71745C3.62325 7.77067 3.53569 7.8435 3.46526 7.93154C3.39482 8.01957 3.34299 8.12099 3.31289 8.22964C3.28279 8.33829 3.27505 8.45192 3.29014 8.56365C3.30523 8.67538 3.34284 8.78289 3.40068 8.87966C3.45853 8.97644 3.53541 9.06047 3.62667 9.12667L6.81333 11.68C6.9016 11.7493 7.00287 11.8003 7.11115 11.8298C7.21943 11.8593 7.33254 11.8668 7.44378 11.8519C7.55502 11.837 7.66214 11.8 7.75882 11.7429C7.8555 11.6859 7.93978 11.6101 8.00667 11.52L12.4933 5.46667C12.5586 5.37871 12.606 5.27875 12.6326 5.17249C12.6592 5.06622 12.6647 4.95575 12.6486 4.84739C12.6325 4.73903 12.5952 4.63491 12.5388 4.54098C12.4824 4.44706 12.4081 4.36517 12.32 4.3Z"
    fill={color}
  />
  <path
    d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6667 9.76811 13.9643 11.4638 12.714 12.714C11.4638 13.9643 9.76811 14.6667 8 14.6667Z"
    fill={color}
  />
</svg>
