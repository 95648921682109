<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 19 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.5 20.0009C9.11667 20.0009 8.74167 19.9342 8.375 19.7925L7.50833 19.4592C5.50833 18.6925 3.81667 17.3509 2.6 15.5842C1.39167 13.8175 0.75 11.7592 0.75 9.61752V3.27585C0.75 2.91752 0.85 2.56752 1.04167 2.26752C1.23333 1.95919 1.50833 1.72585 1.84167 1.57585C4.275 0.500852 6.85 -0.00748086 9.50833 0.000852468C12.1333 -0.0241475 14.725 0.500852 17.15 1.55919C17.4833 1.70919 17.7667 1.95085 17.9583 2.25919C18.15 2.56752 18.25 2.91752 18.25 3.27585V9.61752C18.25 11.7592 17.6083 13.8259 16.4 15.5925C15.1917 17.3592 13.4917 18.6925 11.4917 19.4675L10.625 19.8009C10.2667 19.9425 9.88333 20.0092 9.5 20.0092V20.0009ZM9.34167 1.25085C6.91667 1.25085 4.56667 1.74252 2.35 2.70919C2.25 2.75085 2.15833 2.83419 2.1 2.93419C2.03333 3.03419 2 3.15085 2 3.26752V9.61752C2 11.5009 2.56667 13.3259 3.63333 14.8842C4.7 16.4425 6.2 17.6175 7.95833 18.3009L8.825 18.6342C9.25833 18.8009 9.73333 18.8009 10.175 18.6342L11.0417 18.3009C12.8 17.6259 14.3 16.4425 15.3667 14.8842C16.4333 13.3259 17 11.5092 17 9.61752V3.26752C17 3.14252 16.9667 3.02585 16.9 2.92585C16.8333 2.82585 16.7417 2.75085 16.6417 2.70085C14.3833 1.71752 11.9667 1.23419 9.50833 1.25085C9.45 1.25085 9.4 1.25085 9.34167 1.25085Z"
    fill={color}
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.29167 4.66752C7.875 4.08419 8.675 3.75085 9.5 3.75085C10.325 3.75085 11.125 4.08419 11.7083 4.66752C12.2917 5.25085 12.625 6.05085 12.625 6.87585V7.60919C13.35 7.86752 13.875 8.55919 13.875 9.37585V13.1259C13.875 14.1592 13.0333 15.0009 12 15.0009H7C5.96667 15.0009 5.125 14.1592 5.125 13.1259V9.37585C5.125 8.55919 5.65 7.86752 6.375 7.60919V6.87585C6.375 6.05085 6.70833 5.25085 7.29167 4.66752ZM7.625 7.50085H11.375V6.87585C11.375 6.37585 11.175 5.90085 10.825 5.55085C10.475 5.20085 10 5.00085 9.5 5.00085C9 5.00085 8.525 5.20085 8.175 5.55085M8.175 5.55085C7.825 5.90085 7.625 6.37585 7.625 6.87585V7.50085M7 8.75085C6.65833 8.75085 6.375 9.03419 6.375 9.37585V13.1259C6.375 13.4675 6.65833 13.7509 7 13.7509H12C12.3417 13.7509 12.625 13.4675 12.625 13.1259V9.37585C12.625 9.03419 12.3417 8.75085 12 8.75085H7Z"
    fill={color}
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.5 11.5925C9.675 11.5925 9.81667 11.4509 9.81667 11.2759C9.81667 11.1009 9.675 10.9592 9.5 10.9592V10.3342C8.98333 10.3342 8.56667 10.7509 8.56667 11.2675C8.56667 11.7842 8.98333 12.2009 9.5 12.2009V11.5759V11.5925Z"
    fill={color}
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.5 10.3425C10.0167 10.3425 10.4333 10.7592 10.4333 11.2759C10.4333 11.7925 10.0167 12.2092 9.5 12.2092V11.5842C9.325 11.5842 9.18333 11.4425 9.18333 11.2675C9.18333 11.0925 9.325 10.9509 9.5 10.9509V10.3259V10.3425Z"
    fill={color}
  />
</svg>
