<script lang="ts">
  import type { IconName } from '../../types/icons.types';
  import * as Icons from './icons';

  export let iconName: IconName;
  export let size = '20';
  export let color = 'currentColor';
</script>

<svelte:component this={Icons[iconName]} {size} {color} {...$$restProps} />
