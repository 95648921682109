import { isIos } from '../utils/detectPlatform';

type fnName = 'showSystemLoader' | 'hideSystemLoader' | 'closeWebView';

export function callNative(functionName: fnName, arg?: unknown): void {
  if (isIos()) {
    try {
      window.webkit.messageHandlers[functionName].postMessage(arg);
    } catch (error) {
      console.error(`Error calling iOS function ${functionName}:`, error);
    }
  } else {
    console.log(
      'Not supported on this platform. Tried to call native function:',
      functionName
    );
  }

  // we need to wait for the Android team to implement the JSBridge
  // if (!isIos() && !isAndroid()) {
  //   return console.log(
  //     'Not supported on this platform. Tried to call native function:',
  //     functionName
  //   );
  // }

  // if (isAndroid()) {
  //   try {
  //     window.JSBridge[functionName](arg);
  //   } catch (error) {
  //     console.error(`Error calling Android function ${functionName}:`, error);
  //   }
  // } else {
  //   try {
  //     window.webkit.messageHandlers[functionName].postMessage(arg);
  //   } catch (error) {
  //     console.error(`Error calling iOS function ${functionName}:`, error);
  //   }
  // }
}
