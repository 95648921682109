<script lang="ts">
  import { fade } from 'svelte/transition';
  import { t } from 'svelte-i18n';
  import { createEventDispatcher, onMount } from 'svelte';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import { settingsStore } from '../../store/settingsStore';

  const dispatch = createEventDispatcher();

  type singleMonth = {
    month: number;
    year: number;
    net: number;
    gross: number;
  };

  export let monthData: singleMonth;
  export let highestGross: number;
  export let isSelected = false;
  export let monthIndex: number;

  const progress = tweened(0, {
    duration: 1000,
    easing: cubicOut,
  });

  // how we calculate the graph scale: max value is the highest gross, then we calculate the percentage for
  // each month. From each month (including the one with the highest gross) we subtract 5%, so it matches the design, but
  // it's not optimal? TODO: ask the designer.
  onMount(() => {
    if (monthData && highestGross) {
      progress.set(Math.round((monthData.gross / highestGross) * 100) - 5);
    }
  });

  $: if (monthData && highestGross) {
    progress.set(Math.round((monthData.gross / highestGross) * 100) - 5);
  }
</script>

<div
  class={[
    'relative flex flex-col items-center transition-all duration-300',
    $settingsStore.isGraphExpanded ? 'w-full' : 'w-1',
  ].join(' ')}
  data-cy="bar-graph-single-month"
  on:click={() =>
    $settingsStore.isGraphExpanded ? dispatch('selectMonth', monthIndex) : null}
  on:keydown={() =>
    $settingsStore.isGraphExpanded ? dispatch('selectMonth', monthIndex) : null}
  role="button"
  tabindex="0"
>
  <div
    class={[
      'relative w-1 rounded bg-primary-tint20 transition-all duration-300',
      $settingsStore.isGraphExpanded ? 'h-5/6' : 'h-full',
    ].join(' ')}
  >
    <div
      class="absolute bottom-0 w-1 rounded bg-secondary-base"
      style="height: {$progress}%"
    />
  </div>

  {#if $settingsStore.isGraphExpanded}
    <div
      transition:fade={{ duration: 200 }}
      class={[
        'absolute text-android-label-small font-medium ios:text-ios-caption ios:font-semibold',
        isSelected
          ? '-bottom-[12px] w-full rounded bg-white py-[0.12rem] text-center text-primary-base'
          : '-bottom-[10px] text-primary-tint40',
      ].join(' ')}
    >
      {$t(`months.${monthData.month}`).substring(0, 3)}
    </div>
  {/if}
</div>
