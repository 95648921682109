<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.1 4.297C10.376 4.297 10.6 4.5205 10.6 4.7965L10.6015 6.4725H13.25C13.4255 6.4725 13.5875 6.5645 13.6785 6.7145L15.4285 9.6155C15.475 9.6935 15.5 9.7825 15.5 9.874V12.0495C15.5 12.3255 15.2765 12.5495 15 12.5495H13.7365C13.667 13.36 13.009 14 12.2005 14C11.3915 14 10.733 13.36 10.6635 12.5495H6.7365C6.667 13.36 6.009 14 5.2005 14C4.3915 14 3.733 13.36 3.6635 12.5495H2.4C2.264 12.5495 2.133 12.494 2.0385 12.395C1.9445 12.2965 1.8945 12.164 1.9005 12.0275L2.0005 9.7695C2.013 9.4935 2.271 9.2815 2.522 9.292C2.798 9.3045 3.012 9.538 2.9995 9.814L2.9225 11.5495H3.902C4.179 11.114 4.6565 10.824 5.2005 10.824C5.7445 10.824 6.2215 11.114 6.498 11.5495H9.607L9.6025 6.9835C9.602 6.9795 9.6 6.9765 9.6 6.9725C9.6 6.9685 9.602 6.9655 9.602 6.962L9.6005 5.297H7.25C6.974 5.297 6.75 5.073 6.75 4.797C6.75 4.5205 6.974 4.297 7.25 4.297H10.1ZM12.2005 11.824C11.897 11.824 11.65 12.088 11.65 12.412C11.65 12.7365 11.897 13 12.2005 13C12.5035 13 12.75 12.7365 12.75 12.412C12.75 12.088 12.5035 11.824 12.2005 11.824ZM5.2005 11.824C4.897 11.824 4.65 12.088 4.65 12.412C4.65 12.7365 4.897 13 5.2005 13C5.5035 13 5.75 12.7365 5.75 12.412C5.75 12.088 5.5035 11.824 5.2005 11.824ZM12.968 7.4725H10.603L10.607 11.5495H10.902C11.179 11.114 11.6565 10.824 12.2005 10.824C12.7445 10.824 13.2215 11.114 13.498 11.5495H14.5V10.013L12.968 7.4725ZM3.1 2C4.558 2 5.7005 3.2285 5.7005 4.797C5.7005 6.7175 3.494 8.3905 3.4 8.461C3.311 8.5275 3.2055 8.5605 3.1 8.5605C2.995 8.5605 2.8895 8.5275 2.8005 8.461C2.7065 8.3905 0.5 6.7175 0.5 4.797C0.5 3.2285 1.642 2 3.1 2ZM3.1 3C2.188 3 1.5 3.7725 1.5 4.797C1.5 5.791 2.4775 6.8645 3.1 7.414C3.723 6.8645 4.7005 5.791 4.7005 4.797C4.7005 3.7725 4.0125 3 3.1 3ZM3.1 3.56C3.704 3.56 4.1945 4.061 4.1945 4.676C4.1945 5.291 3.704 5.7915 3.1 5.7915C2.4965 5.7915 2.006 5.291 2.006 4.676C2.006 4.061 2.4965 3.56 3.1 3.56Z"
    fill={color}
  />
</svg>
