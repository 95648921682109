<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.00098 12.0015C3.00098 11.5868 3.33623 11.2515 3.75098 11.2515H18.4405L12.9692 5.78025C12.676 5.487 12.676 5.013 12.9692 4.71975C13.1155 4.5735 13.3075 4.5 13.4995 4.5C13.6915 4.5 13.8835 4.5735 14.0297 4.71975L20.7812 11.4705C20.8495 11.5395 20.905 11.6212 20.9425 11.712C20.9807 11.8027 21.001 11.901 21.001 12.0015C21.001 12.1013 20.9807 12.1995 20.9425 12.2902C20.905 12.381 20.8495 12.4627 20.7812 12.5317L14.0297 19.2825C13.7365 19.5757 13.2625 19.5757 12.9692 19.2825C12.676 18.9892 12.676 18.5153 12.9692 18.222L18.4405 12.7515H3.75098C3.33623 12.7515 3.00098 12.4155 3.00098 12.0015Z"
    fill={color}
  />
</svg>
