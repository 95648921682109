<script lang="ts">
  export let currentPage = 1;
  export let pageCount = 1;
</script>

<div
  class="flex min-h-[21px] items-center rounded-xl bg-primary-tint90 px-2 pb-px pt-px ios:pb-[3px]"
>
  <p
    class="text-android-body-small text-primary-tint40 ios:text-ios-subhead ios:font-semibold"
  >
    {currentPage}/{pageCount}
  </p>
</div>
