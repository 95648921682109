<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.6258 2.22384C14.3675 1.48268 15.5732 1.48268 16.3139 2.22384L17.778 3.68742C18.5181 4.42806 18.5181 5.63382 17.7785 6.37498L17.2972 6.85572C17.2946 6.85832 17.2941 6.86197 17.2915 6.86458C17.2889 6.86666 17.2852 6.8677 17.2826 6.86978L16.3139 7.83908C16.3107 7.84272 16.3097 7.84689 16.3066 7.85002L16.2962 7.85679L7.1923 16.9591L7.01626 17.1356C6.9475 17.2044 6.86104 17.2523 6.76677 17.2742L6.68864 17.2929H17.8129C18.1009 17.2929 18.3337 17.5258 18.3337 17.8138C18.3337 18.1013 18.1009 18.3346 17.8129 18.3346H2.1875C2.11875 18.3346 2.05051 18.3201 1.98749 18.294C1.92395 18.2674 1.86666 18.2289 1.81874 18.1815C1.77655 18.1388 1.7453 18.0883 1.72082 18.0352C1.71405 18.0221 1.70884 18.0096 1.70363 17.9956C1.68228 17.9388 1.66821 17.8799 1.66769 17.8185C1.66665 17.8081 1.66978 17.8039 1.66978 17.7987C1.67082 17.7638 1.67238 17.7289 1.68071 17.6945L2.72709 13.2356C2.74897 13.1408 2.79689 13.0548 2.86564 12.9861L3.03595 12.8158C3.0396 12.8111 3.04116 12.8105 3.04273 12.8095L12.1638 3.68638L13.1378 2.71187L13.6258 2.22384ZM3.59326 14.1033L2.88647 17.1148L5.89852 16.408L3.59326 14.1033ZM12.5299 4.79317L4.14431 13.1809L6.82094 15.8575L15.2013 7.47813L12.5299 4.79317ZM13.5065 3.81659L13.2664 4.05618L15.9383 6.74114L16.1852 6.49477L13.5065 3.81659ZM15.5774 2.96032C15.2425 2.62541 14.6982 2.62541 14.3623 2.96032L14.243 3.07959L16.9217 5.7583L17.0415 5.6385C17.3754 5.3036 17.3754 4.7588 17.041 4.42389L15.5774 2.96032Z"
    fill={color}
  />
</svg>
