<script lang="ts">
  import { getFormattedNumber } from '../utils/formatters';

  export let amount: string | number | null;
  export let type: 'total' | 'netto' | 'voucher' = 'total';
  export let isMain: boolean = false;

  let digit = '';
  let decimal = '';
  const decimalSeparator = ',';
  let style = 'text-primary-base';

  $: {
    if (amount != null) {
      [digit, decimal] = amount.toString().split('.');
    }

    if (type === 'netto') {
      style = 'text-primary-tint40';
    }
    if (type === 'voucher') {
      style = 'text-blue-base';
    }

    if (amount != null) {
      digit = getFormattedNumber(parseInt(digit), 0) ?? '0';
      decimal =
        decimalSeparator +
        (decimal ? (decimal.length === 1 ? decimal + '0' : decimal) : '00');
    }
  }
</script>

{#if amount == null && isMain}
  <p class={`flex items-stretch ${style}`}>
    <span
      class="text-android-title-medium ios:text-ios-headline ios:font-semibold"
    >
      --,
    </span>
    <span
      class="mt-[2px] text-android-label-small ios:text-ios-caption ios:font-semibold"
    >
      --
    </span>
  </p>
{:else if amount == null}
  <span></span>
{:else}
  <p class={`flex items-stretch ${style}`}>
    {#if isMain}
      <span
        class="text-android-title-medium ios:text-ios-headline ios:font-semibold"
      >
        {digit}
      </span>
      <span
        class="mt-[2px] text-android-label-small ios:text-ios-caption ios:font-semibold"
      >
        {decimal}
      </span>
    {:else}
      <span class="text-android-body-small ios:text-ios-footnote">
        {digit}
      </span>
      <span class="text-android-body-small ios:text-ios-tabbar ios:mt-px">
        {decimal}
      </span>
    {/if}
  </p>
{/if}