import { writable } from 'svelte/store';
import { callNative } from '../JSBridge/callNative';
import { navigateBack } from './navigationController';

let loaderTimeout: number | null = null;

const loaderStore = writable<number[]>([]);

function resetLoaderTimeout() {
  if (loaderTimeout) {
    clearTimeout(loaderTimeout);
  }
  loaderTimeout = setTimeout(() => {
    loaderStore.set([]);
    // taking the user back in case of "problems" - loader is visible for 15 seconds.
    navigateBack();
  }, 15000);
}

function showLoader() {
  loaderStore.update((currentLoaders) => {
    const updatedLoaders = [...currentLoaders, Date.now()];

    if (updatedLoaders.length === 1) {
      callNative('showSystemLoader', 'spinner');
    }

    resetLoaderTimeout();

    return updatedLoaders;
  });
}

function hideLoader() {
  loaderStore.update((currentLoaders) => {
    const updatedLoaders = currentLoaders.slice(0, -1);

    if (updatedLoaders.length === 0) {
      callNative('hideSystemLoader');
    }

    resetLoaderTimeout();

    return updatedLoaders;
  });
}

loaderStore.subscribe((loaders) => {
  if (loaders.length === 0 && loaderTimeout) {
    clearTimeout(loaderTimeout);
    loaderTimeout = null;
  }
});

export { showLoader, hideLoader };
