import type { Env } from './types/env.types';

export default {
  MPAY_ENV: 'pre-production',
  MPAY_POSTHOG_TOKEN: 'phc_eXmHJYpjyKe3MXD1PYkTGfsGJ4k45Bv4E5D51qzIShr',
  MPAY_POSTHOG_API_HOST: 'https://analytics.metro-fs.com',
  MPAY_API_BASE_URI: 'https://api.mpay.pp.metro-fs.com',

  IDAM_AUTH: {
    BASE_URL: 'https://idam-pp.metro.ro/authorize/api/oauth2/authorize',
    CLIENT_ID: 'MFS_MPAY_CUST',
    REALM_ID: 'SSO_CUST_RO',
    RESPONSE_TYPE: 'token',
    SCOPE: 'openid',
    USER_TYPE: 'CUST',
  },

  MPAY_SENTRY_DSN:
    'https://6e4014dc9072f699a3ba99baa783cbe3@o4504928279199744.ingest.sentry.io/4506072160337920',
} as Env;
