<script lang="ts">
  export let pageCount: number;
  export let currentPage: number;
</script>

<div class="inline-flex items-start justify-center gap-2">
  {#each { length: pageCount } as _, i}
    <div
      class={[
        'h-2 w-2 rounded-full transition-all duration-300',
        currentPage === i + 1 ? 'bg-blue-base' : 'bg-semantic-invertedMuted',
      ].join(' ')}
    />
  {/each}
</div>
