<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  fill="none"
  viewBox="0 0 20 20"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-rule="evenodd" fill-rule="evenodd">
    <path
      d="m10 1.25c2.7025 0 4.9012 2.41375 4.9012 5.38125 0 1.77812-.8112 3.44875-2.1375 4.44505l-.0006 1.4449 3.8556 1.2763c1.275.4219 2.1313 1.5606 2.1313 2.8325v1.495c0 .345-.2794.625-.625.625h-16.25c-.34563 0-.625-.28-.625-.625v-1.495c0-1.2719.85625-2.41 2.13062-2.8325l3.85625-1.2763v-1.4449c-1.32687-.9963-2.13812-2.6663-2.13812-4.44505 0-2.9675 2.19875-5.38125 4.90125-5.38125zm0 1.25c-2.01313 0-3.65125 1.85312-3.65125 4.13125 0 1.48187.70937 2.85938 1.85062 3.59375.17875.115.28688.3125.28688.525l.00062 2.2219c0 .27-.1725.5093-.42812.5937l-4.285 1.4188c-.76187.2518-1.27375.9137-1.27375 1.6456v.87h15v-.87c0-.7319-.5119-1.3938-1.2744-1.6456l-4.2844-1.4188c-.2556-.0844-.4281-.3237-.4281-.5931v-2.2225c0-.2131.1088-.41.2875-.525 1.1413-.73437 1.8506-2.11188 1.8506-3.59375 0-2.27813-1.6381-4.13125-3.6512-4.13125z"
      fill={color}
    />
  </g>
</svg>
