<script lang="ts">
  export let item;
  export let label;
  export let isChecked;
  export let onChange: (value: string) => void;
</script>

<label
  for={item}
  class="w-full cursor-pointer text-android-title-medium font-medium
text-primary-base ios:text-ios-headline ios:font-semibold"
>
  {label}
</label>
<input
  type="checkbox"
  id={item}
  name="checkbox"
  value={item}
  checked={isChecked}
  on:change={() => onChange(item)}
  class="after:shadow-md absolute right-12 top-3.5 h-0 w-0 border-0 before:absolute before:h-6 before:w-12 before:rounded-full before:bg-primary-tint60 before:content-[''] after:absolute after:ml-0.5 after:mt-0.5 after:h-5 after:w-5 after:rounded-full after:bg-white after:duration-100 after:content-[''] before:checked:bg-semantic-interaction checked:after:translate-x-6 checked:after:duration-300 focus-within:ring-0 focus:ring-offset-0"
/>
