<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="cancel-fill">
    <mask
      id="mask0_11815_9451"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect id="Bounding box" width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_11815_9451)">
      <g id="Vector">
        <path
          d="M9.79158 10.7841L12.6895 13.682C12.82 13.8125 12.9841 13.8793 13.1818 13.8824C13.3795 13.8854 13.5466 13.8186 13.6832 13.682C13.8198 13.5454 13.8881 13.3797 13.8881 13.1851C13.8881 12.9905 13.8198 12.8249 13.6832 12.6883L10.7853 9.79036L13.6832 6.89248C13.8138 6.76192 13.8806 6.59781 13.8836 6.40015C13.8866 6.2025 13.8198 6.03537 13.6832 5.89876C13.5466 5.76214 13.381 5.69383 13.1863 5.69383C12.9917 5.69383 12.8261 5.76214 12.6895 5.89876L9.79158 8.79662L6.8937 5.89876C6.76314 5.76819 6.59903 5.70139 6.40137 5.69838C6.20372 5.69534 6.03659 5.76214 5.89998 5.89876C5.76336 6.03537 5.69505 6.20099 5.69505 6.39562C5.69505 6.59025 5.76336 6.75587 5.89998 6.89248L8.79784 9.79036L5.89998 12.6883C5.76941 12.8188 5.70261 12.9829 5.6996 13.1806C5.69656 13.3782 5.76336 13.5454 5.89998 13.682C6.03659 13.8186 6.20221 13.8869 6.39684 13.8869C6.59147 13.8869 6.75709 13.8186 6.8937 13.682L9.79158 10.7841ZM9.79316 18.7487C8.55414 18.7487 7.38953 18.5136 6.29931 18.0433C5.20908 17.5731 4.26074 16.9349 3.45428 16.1289C2.64781 15.3227 2.00935 14.3748 1.53891 13.2851C1.06847 12.1953 0.833252 11.031 0.833252 9.79194C0.833252 8.55293 1.06837 7.38831 1.5386 6.29809C2.00884 5.20786 2.647 4.25951 3.4531 3.45306C4.25921 2.64659 5.20714 2.00813 6.29688 1.53769C7.38661 1.06725 8.55099 0.832031 9.79001 0.832031C11.029 0.832031 12.1936 1.06715 13.2839 1.53738C14.3741 2.00762 15.3224 2.64578 16.1289 3.45188C16.9354 4.25799 17.5738 5.20592 18.0443 6.29566C18.5147 7.38539 18.7499 8.54977 18.7499 9.78878C18.7499 11.0278 18.5148 12.1924 18.0446 13.2826C17.5743 14.3729 16.9362 15.3212 16.1301 16.1277C15.324 16.9341 14.376 17.5726 13.2863 18.043C12.1966 18.5135 11.0322 18.7487 9.79316 18.7487Z"
          fill={color}
        />
      </g>
    </g>
  </g>
</svg>
