<script lang="ts">
  import Icon from '../components/Icon.svelte';
  import { navigateBack } from '../JSBridge/navigationController';
  import { topBar } from '../../store/TopBarStore';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import { isAndroid } from '../utils/detectPlatform';

  const onBackClick = () => {
    const eventKey =
      window.location.pathname === '/'
        ? EventsMapKeys.CLOSE_APP
        : EventsMapKeys.BACK_ARROW_CLICK;

    trackEvents(eventKey, $topBar.context);
    navigateBack();
  };
</script>

<nav
  data-cy="top-bar"
  class="fixed z-50 grid w-screen grid-cols-4 gap-4 bg-primary-base px-4 text-white {!isAndroid()
    ? 'h-topBar'
    : 'h-0'}"
>
  <div class="col-span-1 flex items-center">
    <button on:click={onBackClick}>
      <Icon iconName="ChevronLeft" />
    </button>
  </div>

  <div
    class="col-span-2 flex items-center justify-center text-android-title-large ios:text-[17px] ios:font-semibold"
  >
    {$topBar.title}
  </div>

  <div class="col-span-1 flex items-center justify-end">
    <button
      class="text-android-title-small transition-opacity ios:text-ios-body"
      class:hidden={!$topBar.button.label || !$topBar.button.onClick}
      on:click={$topBar.button.onClick}
    >
      {$topBar.button.label}
    </button>
  </div>
</nav>
