<script lang="ts">
  import type { Payment } from '../../types/mpay.types';
  import Icon from './Icon.svelte';
  import type { IconName } from '../../types/icons.types';
  import { paymentCodes } from '../constants';
  import ListInvoiceAmount from './ListInvoiceAmount.svelte';
  import { onMount } from 'svelte';
  import { getPaymentMethodLocalisedString } from '../utils/paymentCodes';

  export let payment: Payment;
  let iconName: IconName;

  const paymentCodeToIconMap: Record<string, IconName> = {
    // MCredit
    [paymentCodes[600]]: 'Flex',
    // Cash
    [paymentCodes[1]]: 'CashPaymentCoins',
    // Cards
    [paymentCodes[300]]: 'Card',
    [paymentCodes[301]]: 'Card',
    [paymentCodes[400]]: 'Card',
  };

  const getIconName = (code: string): IconName =>
    paymentCodeToIconMap[code] || 'Business';

  onMount(() => (iconName = getIconName(payment.paymentCode.toString())));
</script>

<div
  class="grid grid-cols-[40px_minmax(0,1fr)_80px] items-center gap-3 rounded-2xl bg-semantic-appBackground p-4 pb-[15px] pt-[17px]"
>
  <div
    class="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-semantic-inverted p-2 text-primary-base shadow-small"
  >
    <Icon {iconName} />
  </div>

  <div class="w-max-1/2 flex w-full flex-col">
    <p
      class="text-android-title-medium text-primary-base ios:text-ios-headline ios:font-semibold"
    >
      {getPaymentMethodLocalisedString(payment.paymentCode)}
    </p>
  </div>

  <div class="flex items-end justify-end">
    <ListInvoiceAmount isMain amount={payment.paymentAmount} />
  </div>
</div>
