<script lang="ts">
  import { t } from 'svelte-i18n';
  import { getFormattedNumber } from '../utils/formatters';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import { Target } from '../utils/analytics/keys';
  import { getAccountId, getCountry } from '../utils/localStorage';
  import { MPAY_API_BASE_URI } from '../../env';
  import { MPayApi } from '../constants';
  import { sendRequest } from '../utils/fetch';
  import SpendGraphMonth from './SpendGraphMonth.svelte';
  import SpendGraphTotal from './SpendGraphTotal.svelte';
  import SkeletonLoader from './SkeletonLoader.svelte';
  import { settingsStore } from '../../store/settingsStore';
  import { invoicesStore } from '../../store/invoicesStore';
  import type { InvoicesSummary } from '../../types/mpay.types';

  let selectedIndex = 5;
  let h2Classes: string = '';
  let grossValueLength = 0;

  let fetchInvoicesSummaryPromise = fetchInvoicesSummary();

  async function fetchInvoicesSummary() {
    const url = `${MPAY_API_BASE_URI}/${MPayApi.INVOICES}/${getCountry()}/${getAccountId()}/summary`;

    const response = await sendRequest<InvoicesSummary[]>(url);

    // show oldest to current (left to right)
    invoicesStore.setSummary(response.reverse());

    grossValueLength =
      $invoicesStore.summary[selectedIndex].gross.toString().length;

    return $invoicesStore;
  }

  const onGraphClick = () => {
    trackEvents(EventsMapKeys.OVERVIEW_CLICK, Target.TotalOverviewSum);
    shrinkGraph();
  };

  const onSummaryClick = () => {
    if (!$settingsStore.isGraphExpanded) {
      trackEvents(EventsMapKeys.OVERVIEW_CLICK, Target.OverviewGraph);
      settingsStore.setIsGraphExpanded(true);
    }
  };

  const shrinkGraph = () => {
    if ($settingsStore.isGraphExpanded) {
      settingsStore.setIsGraphExpanded(false);
      selectedIndex = 5;
    }
  };

  const onSelectMonth = (e: { detail: number }) => {
    selectedIndex = e.detail;
    trackEvents(EventsMapKeys.OVERVIEW_CLICK, Target.OverviewGraphSingleMonth, {
      month: e.detail,
    });
  };

  $: bestMonthByGross = $invoicesStore.summary.reduce(
    (max, curr) => (curr.gross > max.gross ? curr : max),
    $invoicesStore.summary[0]
  );

  $: {
    const baseClass = 'font-medium ios:font-normal';
    const expandedClasses = $settingsStore.isGraphExpanded
      ? 'text-android-title-large ios:text-ios-title2'
      : 'text-android-display-small ios:text-ios-xlarge-title';
    const largeValueClasses = $settingsStore.isGraphExpanded
      ? 'text-android-title-medium ios:text-ios-title3'
      : 'text-3xl ios:text-ios-large-title';

    h2Classes = `${grossValueLength >= 6 ? largeValueClasses : expandedClasses} ${baseClass}`;
  }
</script>

<div class="flex h-full justify-between">
  {#await fetchInvoicesSummaryPromise}
    <div class="flex h-full w-full animate-pulse">
      <div class="flex h-full w-full flex-col justify-center">
        {#each Array(3) as _loader, index (index)}
          <div
            class="mb-3 last:mb-0"
            style={`width: calc(${index + 2}/5 * 100%)`}
          >
            <SkeletonLoader cssClasses="w-full h-2 rounded-xl" />
          </div>
        {/each}
      </div>

      <div class="flex h-full w-full justify-end">
        {#each Array(6) as _loader, index (index)}
          <SkeletonLoader cssClasses="h-full w-1 rounded-xl ml-[17.5px]" />
        {/each}
      </div>
    </div>
  {:then $invoicesStore}
    <SpendGraphTotal
      {h2Classes}
      month={$t(`months.${$invoicesStore.summary[selectedIndex].month}`)}
      year={$invoicesStore.summary[selectedIndex].year}
      gross={getFormattedNumber($invoicesStore.summary[selectedIndex].gross, 0)}
      net={getFormattedNumber($invoicesStore.summary[selectedIndex].net, 0)}
      currencyCode={$invoicesStore.summary[selectedIndex].currencyCode}
      {onGraphClick}
    />
    <div
      class={[
        'flex justify-between pl-4 transition-all duration-300',
        $settingsStore.isGraphExpanded ? 'w-56 pb-2' : 'w-32',
      ].join(' ')}
      on:click={onSummaryClick}
      on:keydown={onSummaryClick}
      role="button"
      tabindex="0"
      data-cy="bar-graph-all-months"
    >
      {#each $invoicesStore.summary as monthData, i}
        <SpendGraphMonth
          {monthData}
          highestGross={bestMonthByGross.gross}
          monthIndex={i}
          isSelected={i === selectedIndex}
          on:selectMonth={onSelectMonth}
        />
      {/each}
    </div>
  {:catch}
    <SpendGraphTotal
      {h2Classes}
      month={$t(`months.${new Date().getUTCMonth() + 1}`)}
      year={new Date().getFullYear()}
      gross={'0'}
      net={'0'}
      {onGraphClick}
    />
  {/await}
</div>
