<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6 11.939C6 12.3208 6.146 12.6465 6.44922 12.9385L15.209 21.5073C15.4448 21.7544 15.7593 21.8779 16.1187 21.8779C16.8486 21.8779 17.4214 21.3164 17.4214 20.5752C17.4214 20.2158 17.2754 19.8901 17.0283 19.6431L9.1333 11.939L17.0283 4.23486C17.2754 3.97656 17.4214 3.65088 17.4214 3.2915C17.4214 2.56152 16.8486 2 16.1187 2C15.7593 2 15.4448 2.12354 15.209 2.37061L6.44922 10.9395C6.146 11.2314 6.01123 11.5571 6 11.939Z"
    fill={color}
  />
</svg>
