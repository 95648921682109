<script lang="ts">
  import type { IconName } from '../../types/icons.types';
  import Icon from './Icon.svelte';

  export let type: 'default' | 'outlined' | 'inverted' = 'default';
  export let isDisabled = false;
  export let label: string;
  export let leadingIcon: IconName | null = null;
  export let trailingIcon: IconName | null = null;
  export let onClick: () => void;
  export let id = '';

  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

</script>

<button
  class={`text-inherit relative flex items-center justify-center gap-4 overflow-hidden rounded px-6 py-3 text-android-title-small transition-opacity ios:text-ios-body focus:outline-none
  ${!leadingIcon && !trailingIcon ? 'w-full' : ''}

  ${type === 'default' ? 'bg-blue-base text-semantic-inverted' : ''}
  ${type === 'default' && isDisabled ? 'bg-semantic-disabled text-semantic-inverted' : ''}
  ${type === 'default' && !isDisabled ?  'active:bg-blue-tint20': ''}

  ${type === 'outlined' ? 'border border-blue-base bg-semantic-inverted text-blue-base' : ''}
  ${type === 'outlined' && isDisabled ? 'border border-semantic-disabled bg-semantic-inverted text-semantic-disabled' : ''}
  ${type === 'outlined' && !isDisabled ?'active:border-blue-tint20 active:text-blue-tint20' : ''}

  ${type === 'inverted' ? 'border-none bg-semantic-inverted text-blue-base' : ''}
  ${type === 'inverted' && isDisabled ? 'border-none bg-semantic-inverted text-semantic-disabled' : ''}
  ${type === 'inverted' && !isDisabled ? 'active:bg-blue-tint90': ''}
`}
  disabled={isDisabled}
  data-cy={id}
  on:click={handleClick}
  >
  {#if leadingIcon}
    <Icon iconName={leadingIcon} />
  {/if}

  {label}

  {#if trailingIcon}
    <Icon iconName={trailingIcon} />
  {/if}
</button>