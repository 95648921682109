<section class="w-full pb-7 pt-8">
  <svg
    width="276"
    height="188"
    viewBox="0 0 276 188"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_7366_11089"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="180"
      width="273"
      height="5"
    >
      <path
        d="M0.466797 180.533H272.533V184.067H0.466797V180.533Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_7366_11089)">
      <path
        d="M272.533 182.3C272.533 183.275 271.742 184.067 270.767 184.067H2.23346C1.25844 184.067 0.466797 183.275 0.466797 182.3C0.466797 181.325 1.25844 180.533 2.23346 180.533H270.767C271.742 180.533 272.533 181.325 272.533 182.3Z"
        fill="#E6E8EB"
      />
      <path
        d="M123.25 182.3H13.7168"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.64993 182.3H5.7666"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M131.2 182.3H130.317"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M267.233 182.3H138.267"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <mask
      id="mask1_7366_11089"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="4"
      y="0"
      width="61"
      height="61"
    >
      <path d="M4 0.333313H64.95V60.4H4V0.333313Z" fill="white" />
    </mask>
    <g mask="url(#mask1_7366_11089)">
      <path
        d="M34.4751 17.9999C41.3002 17.9999 46.8417 23.5414 46.8417 30.3666C46.8417 37.1918 41.3002 42.7333 34.4751 42.7333C27.6499 42.7333 22.1084 37.1918 22.1084 30.3666C22.1084 23.5414 27.6499 17.9999 34.4751 17.9999Z"
        fill="#F2F3F5"
      />
      <path
        d="M34.4751 17.9999C41.3002 17.9999 46.8417 23.5414 46.8417 30.3666C46.8417 37.1918 41.3002 42.7333 34.4751 42.7333C27.6499 42.7333 22.1084 37.1918 22.1084 30.3666C22.1084 23.5414 27.6499 17.9999 34.4751 17.9999Z"
        stroke="#CCD0D6"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <mask
        id="mask2_7366_11089"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="-5"
        y="-9"
        width="79"
        height="79"
      >
        <path
          d="M16.4766 -8.45074L73.5508 12.9369L52.4732 69.184L-4.60111 47.7964L16.4766 -8.45074Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_7366_11089)">
        <path
          d="M41.2939 12.169L44.3936 3.8974"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.5561 56.8358L27.6558 48.5642"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.6717 37.1859L60.9434 40.2856"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.00522 20.4477L16.2769 23.5474"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.6686 22.0916L60.6289 18.471"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.32094 42.2622L16.2812 38.6417"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M42.7496 48.5604L46.3701 56.5208"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.5791 4.21248L26.1997 12.1728"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <mask
      id="mask3_7366_11089"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="210"
      y="131"
      width="66"
      height="57"
    >
      <path
        d="M215.217 131.637L275.055 136.872L270.667 187.03L210.829 181.795L215.217 131.637Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_7366_11089)">
      <path
        d="M232.917 137.092C225.698 140.654 220.453 147.803 219.7 156.41C218.551 169.539 228.26 181.1 241.38 182.248C254.501 183.395 266.07 173.696 267.218 160.567C267.968 151.996 264.084 144.093 257.648 139.318"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M242.909 149.577C233.916 148.79 225.992 155.439 225.205 164.432C224.855 168.436 224.474 172.792 225.678 174.227"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M240.06 182.136C249.053 182.922 256.977 176.274 257.764 167.28"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M233.637 180.115C243.655 177.506 251.418 168.84 252.371 157.945C252.776 153.317 251.905 148.878 250.057 144.974"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M238.069 149.153L240.214 144.907"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M231.601 152.134L231.507 148.136"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M251.301 160.065L246.923 154.361"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M241.923 176.092C241.923 176.092 237.352 172.588 236.934 167.231C236.516 161.875 239.101 157.667 239.101 157.667"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M254.909 174.568L252.979 171.295"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M238.421 170.465L241.369 167.176"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M236.529 166.753L232.839 163.326"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M228.565 176.696L230.138 168.854"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M227.025 158.828L232.899 157.568"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M227.245 141.113L232.486 142.014"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M223.34 145.204L223.4 139.446"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M219.776 155.533L216.718 149.945"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M219.81 165.29L214.761 162.188"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M261.147 174.227C261.147 174.227 263.561 166.901 262.742 161.066C261.841 154.638 258.309 150.924 258.309 150.924"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M262.378 160.147L258.055 158.882"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M252.148 150.385L254.81 145.298"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M248.81 168.27L246.077 164.041"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M245.23 173.721L248.673 174.909"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M229.676 174.133L232.47 172.604"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M267.317 154.372L269.616 148.366"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M264.336 147.904L260.013 146.639"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M260.436 141.8L260.821 137.4"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M266.948 163.65L271.216 160.477"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M261.586 174.265L267.421 173.446"
        stroke="#99A1AD"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <mask
      id="mask4_7366_11089"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="48"
      y="87"
      width="70"
      height="98"
    >
      <path d="M48.167 87.7833H117.067V184.067H48.167V87.7833Z" fill="white" />
    </mask>
    <g mask="url(#mask4_7366_11089)">
      <path
        d="M82.6171 93.0833C78.2976 93.0833 74.6671 96.7226 74.6671 101.033V131.95H67.1587C65.0122 131.95 63.1837 130.148 63.1837 127.975C63.1837 127.975 63.1837 113.426 63.1837 113.4C63.1837 110.414 60.7546 108.1 57.8837 108.1C55.0129 108.1 52.5837 110.414 52.5837 113.4V127.975C52.7074 135.757 58.8731 142.55 66.4962 142.55H74.6671V180.533H90.5671V161.1H98.0754C106.025 161.1 112.65 154.475 112.65 146.525V131.95C112.65 128.964 110.221 126.65 107.35 126.65C104.48 126.65 102.05 128.964 102.05 131.95V146.525C102.05 148.724 100.275 150.5 98.0754 150.5H90.5671V101.033C90.5671 96.7226 86.9366 93.0833 82.6171 93.0833Z"
        fill="white"
      />
      <path
        d="M90.5671 180.533H84.3838V93.0833C87.8023 93.0833 90.5671 95.8481 90.5671 99.2666V152.39C90.5671 154.943 92.5811 157.019 95.1075 157.125H95.3018L98.6938 156.772C103.852 156.224 107.783 151.94 107.916 146.79V146.525V126.65C110.53 126.65 112.65 128.77 112.65 131.385V143.875C112.65 153.282 105.098 160.932 95.7258 161.1H95.3018H90.5671V180.533Z"
        fill="#F2F3F5"
      />
      <path
        d="M52.5835 127.533V127.975C52.5835 135.934 58.9523 142.4 66.8758 142.55H67.1585H74.6668C77.1048 142.55 79.0835 140.571 79.0835 138.133V137.709H62.7595C57.2298 137.709 52.7248 133.293 52.5835 127.789V127.533Z"
        fill="#F2F3F5"
      />
      <path
        d="M57.8838 108.1V123.117C57.8838 127.993 61.8411 131.95 66.7171 131.95H67.6005C65.4716 131.95 63.7315 130.272 63.6255 128.16V127.975V113.842C63.6255 110.741 61.1698 108.215 58.087 108.1H57.8838Z"
        fill="#F2F3F5"
      />
      <path
        d="M90.5671 182.3V161.1H98.0754C106.025 161.1 112.65 154.475 112.65 146.525V131.95C112.65 128.964 110.221 126.65 107.35 126.65C104.48 126.65 102.05 128.964 102.05 131.95V146.525C102.05 148.724 100.275 150.5 98.0754 150.5H90.5671V101.033C90.5671 96.7315 86.9366 93.0833 82.6171 93.0833C78.2976 93.0833 74.6671 96.7315 74.6671 101.033V131.95H67.1587C65.0122 131.95 63.1837 130.148 63.1837 127.975V113.4C63.1837 110.414 60.7546 108.1 57.8837 108.1C55.0129 108.1 52.5837 110.414 52.5837 113.4V127.975C52.7074 135.757 58.8819 142.55 66.4962 142.55H74.6671V182.3"
        stroke="#32425B"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <mask
        id="mask5_7366_11089"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="79"
        y="99"
        width="7"
        height="72"
      >
        <path
          d="M79.9712 99.7083H85.2712V170.375H79.9712V99.7083Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_7366_11089)">
        <path
          d="M82.1881 101.917H82.1704"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M82.1881 115.167H82.1704"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M82.1881 128.417H82.1704"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M82.1881 141.667H82.1704"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M82.1881 154.917H82.1704"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M82.1881 168.167H82.1704"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <mask
        id="mask6_7366_11089"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="48"
        y="87"
        width="70"
        height="84"
      >
        <path
          d="M48.167 87.7833H117.067V170.817H48.167V87.7833Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_7366_11089)">
        <path
          d="M74.6671 101.033H71.1338"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.5836 116.05H49.9336"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M65.8336 116.05H63.1836"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.5836 123.117H49.9336"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M65.8336 123.117H63.1836"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M94.1002 101.033H90.5669"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M76.4337 94.85L74.667 93.0833"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.5835 108.983L51.7002 108.1"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.3003 108.983L63.1836 108.1"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M88.8003 94.85L90.567 93.0833"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M82.6172 93.0833V89.55"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M57.8838 108.1V105.45"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M102.05 134.6H99.4004"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M115.3 134.6H112.65"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M102.05 141.667H99.4004"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M115.3 141.667H112.65"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M102.05 127.533L101.167 126.65"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M111.767 127.533L112.65 126.65"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M107.351 126.65V124"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M74.6671 109.867H71.1338"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M94.1002 109.867H90.5669"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M74.6671 150.5H71.1338"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M74.6671 159.333H71.1338"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M74.6671 168.167H71.1338"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M94.1002 168.167H90.5669"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M94.1002 118.7H90.5669"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M94.1002 127.533H90.5669"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M94.1002 136.367H90.5669"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <mask
      id="mask7_7366_11089"
      style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="127"
      y="57"
      width="82"
      height="128"
    >
      <path
        d="M127.667 57.3083H208.934V184.067H127.667V57.3083Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask7_7366_11089)">
      <path
        d="M168.3 63.0499C174.068 63.0499 178.9 67.9083 178.9 73.6499V105.45H184.2C187.062 105.45 189.5 103.047 189.5 100.15C189.5 100.15 189.5 80.7519 189.5 80.7166C189.5 76.7416 192.742 73.6499 196.567 73.6499C200.392 73.6499 203.633 76.7416 203.633 80.7166V100.15C203.466 110.52 195.242 119.583 185.083 119.583H178.9V180.533H157.7V144.317H152.4C141.8 144.317 132.967 135.483 132.967 124.883V105.45C132.967 101.475 136.209 98.3833 140.033 98.3833C143.858 98.3833 147.1 101.475 147.1 105.45V124.883C147.1 127.807 149.476 130.183 152.4 130.183H157.7V73.6499C157.7 67.9083 162.532 63.0499 168.3 63.0499Z"
        fill="white"
      />
      <path
        d="M178.9 180.533H171.833V63.4916C175.738 63.4916 178.9 66.654 178.9 70.5583V109.001V109.178C179.006 111.951 181.232 114.177 184.006 114.283H184.2L186.064 114.054C192.972 113.188 198.183 107.384 198.333 100.45V100.15V73.65C201.257 73.65 203.633 76.0261 203.633 78.95V100.15C203.633 110.776 195.109 119.407 184.518 119.583H184.2H178.9V180.533Z"
        fill="#F2F3F5"
      />
      <path
        d="M132.967 125.175V124.883C132.967 135.51 141.491 144.14 152.082 144.317H152.4H157.7C159.652 144.317 161.233 142.735 161.233 140.783V140.342H148.425C139.98 140.342 133.126 133.575 132.967 125.175Z"
        fill="#F2F3F5"
      />
      <path
        d="M141.8 98.3833V121.35C141.8 126.226 145.758 130.183 150.634 130.183H152.4C149.538 130.183 147.206 127.913 147.1 125.078V124.883V103.683C147.1 100.821 144.83 98.4893 141.995 98.3833H141.8Z"
        fill="#F2F3F5"
      />
      <path
        d="M157.7 182.3V144.317H152.4C141.8 144.317 132.967 135.483 132.967 124.883V105.45C132.967 101.475 136.209 98.3833 140.033 98.3833C143.858 98.3833 147.1 101.475 147.1 105.45V124.883C147.1 127.807 149.476 130.183 152.4 130.183H157.7V73.6499C157.7 67.9083 162.532 63.0499 168.3 63.0499C174.068 63.0499 178.9 67.9083 178.9 73.6499V105.45H184.2C187.062 105.45 189.5 103.047 189.5 100.15V80.7166C189.5 76.7416 192.742 73.6499 196.567 73.6499C200.392 73.6499 203.633 76.7416 203.633 80.7166V100.15C203.466 110.52 195.242 119.583 185.083 119.583H178.9V182.3"
        stroke="#32425B"
        stroke-width="3.53333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <mask
        id="mask8_7366_11089"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="127"
        y="57"
        width="82"
        height="114"
      >
        <path
          d="M127.667 57.3083H208.934V170.375H127.667V57.3083Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask8_7366_11089)">
        <path
          d="M132.967 107.217H129.434"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M150.634 107.217H147.101"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M157.7 88.6666H154.167"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M157.7 154.917H154.167"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M157.7 168.167H154.167"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M182.434 154.917H178.9"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M182.434 141.667H178.9"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M182.434 128.417H178.9"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M182.434 168.167H178.9"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M157.7 76.2999H154.167"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M160.35 65.7L158.583 63.9333"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M176.25 65.7L178.017 63.9333"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M133.85 100.15L132.083 98.3833"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M146.217 100.15L147.983 98.3833"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M140.034 98.3833V94.85"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M132.967 116.05H129.434"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M150.634 116.05H147.101"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M132.967 124.883H129.434"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M189.5 82.4833H185.967"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M207.167 82.4833H203.634"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M190.384 75.4166L188.617 73.65"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M202.75 75.4166L204.517 73.65"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M196.567 73.65V70.1166"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M168.3 63.0499V59.5166"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M189.5 91.3166H185.967"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M207.167 91.3166H203.634"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M207.167 100.15H203.634"
          stroke="#32425B"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <mask
        id="mask9_7366_11089"
        style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="137"
        y="74"
        width="62"
        height="97"
      >
        <path d="M137.83 74.0916H198.78V170.375H137.83V74.0916Z" fill="white" />
      </mask>
      <g mask="url(#mask9_7366_11089)">
        <path
          d="M168.314 76.2999H168.296"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M168.314 88.6666H168.296"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M168.314 101.917H168.296"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M168.314 115.167H168.296"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M168.314 128.417H168.296"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M140.047 107.217H140.029"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M140.047 116.05H140.029"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M168.314 141.667H168.296"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M168.314 154.917H168.296"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M140.047 124.883H140.029"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M143.58 132.833H143.562"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M151.53 137.25H151.513"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M168.314 168.167H168.296"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M196.58 82.4833H196.562"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M196.58 91.3166H196.562"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M196.58 100.15H196.562"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M193.047 108.983H193.029"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M184.214 112.517H184.196"
          stroke="#CCD0D6"
          stroke-width="3.53333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
</section>

<style scoped>
  section {
    display: flex;
    justify-content: center;
  }
</style>
