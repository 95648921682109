<script lang="ts">
  import { onMount } from 'svelte';

  export let granted: number;
  export let exhausted: number;

  const calculateScale = (limit: number, used: number) => {
    if (limit === 0) {
      return 0;
    }

    return (used / limit) * 350;
  };

  onMount(() => {
    const path = document.getElementById('svg-meter');

    const pathTo = 350 - calculateScale(granted, exhausted);
    if (!granted || !exhausted || pathTo > 350) {
      return;
    }

    if (path) {
      setTimeout(() => {
        path.style.strokeDashoffset = String(Math.max(0, pathTo));
      }, 500);
    }
  });
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="300"
  height="300"
  viewBox="12.5 10 175 170"
  class="absolute -top-5 left-0 right-0 mx-auto"
>
  <path
    id="svg-meter-bg"
    stroke="#33578E"
    d="M41 149.5a77 77 0 1 1 117.93 0"
    fill="none"
    stroke-linecap="round"
  />
  <path
    id="svg-meter"
    stroke="#FFED00"
    d="M41 149.5a77 77 0 1 1 117.93 0"
    fill="none"
    stroke-dasharray="350"
    stroke-dashoffset="350"
    stroke-linecap="round"
  />
</svg>

<style>
  svg path {
    will-change: auto;
    stroke-width: 3px;
    stroke-miterlimit: round;
    transition: stroke-dashoffset 850ms ease-in-out;
  }
</style>
