<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  fill={color}
  viewBox="0 0 10 8"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.98351 6.77383C4.70413 6.77383 4.46467 6.6541 4.30503 6.45455L0.353892 2.02449C0.114429 1.7052 0.154339 1.22628 0.473623 0.986814C0.792907 0.747351 1.23192 0.747351 1.47139 1.06663L4.90369 4.97786C4.9436 5.01777 4.98351 5.01777 5.06333 4.97786L8.49563 1.06663C8.775 0.747351 9.21402 0.70744 9.5333 0.986814C9.85259 1.26619 9.8925 1.7052 9.61312 2.02449C9.61312 2.02449 9.61312 2.02449 9.57321 2.0644L5.66199 6.49446C5.50234 6.6541 5.22297 6.77383 4.98351 6.77383Z"
    fill={color}
  />
</svg>
